import React, { useState, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast, Toaster } from "react-hot-toast";
import Sidebar from "@pages/(startgreen)/views/(dashboard)/Sidebar";
import Navbar from "@pages/(startgreen)/views/(dashboard)/Navbar";
import { navItems } from "@pages/(startgreen)/views/(dashboard)/Sidebar/navitems.ts";
import {
	createVisitors,
	fetchHackathonCandidates,
	validateBulkSubmissions,
} from "@api/service/startgreen.services.ts";
import { useDebounceFn, useDocumentVisibility, useUpdateEffect } from "ahooks";
import SearchBar from "@pages/(startgreen)/views/(hackathon)/hackathon-components/SearchBar.tsx";
import RefreshButton from "@pages/(startgreen)/views/(hackathon)/hackathon-components/RefreshButton.tsx";
import ColumnToggle from "@pages/(startgreen)/views/(hackathon)/hackathon-components/ColumnToggle.tsx";
import CandidatesTable from "@pages/(startgreen)/views/(hackathon)/hackathon-components/HackathonCandidateTable.tsx";
import StatusTabs from "@pages/(startgreen)/views/(hackathon)/hackathon-components/StatusTabs.tsx";
import {
	addCandidates,
	fetchRooms,
	invalidateCandidatePresence,
	validateCandidatePresence,
} from "@pages/(startgreen)/views/(hackathon)/mutations/hackathon.service.ts";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Tooltip } from "@pages/(startgreen)/views/(dashboard)/Tooltip.tsx";
import { Button, CircularProgress, IconButton, TextField } from "@mui/material";
import { cn } from "@core/utils";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { appConfig } from "@config/appConfig.ts";
import JudgesManager from "@pages/(startgreen)/views/(hackathon)/hackathon-components/JudgeManager.tsx";
import EmailImporter from "@pages/(startgreen)/views/(hackathon)/hackathon-components/EmailImporter.tsx";

const useCandidates = (status: string, search: string) => {
	return useQuery({
		queryKey: ["hackathonCandidates", status, search],
		queryFn: async () => {
			try {
				const result = await fetchHackathonCandidates(status, search);
				return Array.isArray(result) ? result : [];
			} catch (error) {
				console.error("Error fetching candidates:", error);
				return [];
			}
		},
		refetchOnWindowFocus: true,
		refetchInterval: 60_000,
		staleTime: 60_000,
	});
};

const useRooms = () => {
	return useQuery({
		queryKey: ["rooms"],
		queryFn: fetchRooms,
		refetchOnWindowFocus: false,
	});
};

export const HackathonScreen = ({ navbarTitle, submissionType = "USER" }) => {
	const documentVisibility = useDocumentVisibility();
	const queryClient = useQueryClient();
	const [selectedTab, setSelectedTab] = useState("all");
	const [searchTerm, setSearchTerm] = useState("");
	const [visibleColumns, setVisibleColumns] = useState({
		room: true,
		name: true,
		email: true,
		lastName: true,
		present: true,
	});
	const [selectedUserType, setSelectedUserType] = useState("CANDIDATE");
	const [isOpen, setIsOpen] = useState(false);

	const handleSubmitEmails = async (emails: string[]) => {
		await addCandidates({ emails });
		refetch();
	};

	const {
		data: hackathonCandidates,
		isLoading,
		refetch,
	} = useCandidates(selectedTab, searchTerm);

	const { data: rooms, isLoading: isLoadingRooms } = useRooms();

	const { run: debouncedInvalidateQueries } = useDebounceFn(
		() => {
			queryClient.invalidateQueries(["hackathonCandidates", selectedTab]);
		},
		{ wait: 1000 }
	);

	const validateCandidate = useMutation({
		mutationFn: validateCandidatePresence,
		onMutate: () => {},
		onSuccess: async () => {
			await queryClient.invalidateQueries(["submissions", selectedTab]);
		},
	});

	const invalidateCandidate = useMutation({
		mutationFn: invalidateCandidatePresence,
		onMutate: () => {},
		onSuccess: async () => {
			await queryClient.invalidateQueries(["submissions", selectedTab]);
		},
	});

	const changeRoomMutation = useMutation({
		mutationFn: async ({ candidateId, roomId }) => {
			await axios.put(
				`${appConfig.startGreenUrl}/hackathon/change-room/${candidateId}`,
				{ roomId },
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["hackathonCandidates", selectedTab]);
		},
	});

	const sendBadgeMutation = useMutation({
		mutationFn: async ({ candidateIds }) => {
			// Map each candidate ID into a HackathonBadgeDto object.
			const payload = candidateIds.map((id) => ({ id }));
			await axios.post(
				`${appConfig.startGreenUrl}/hackathon/send-badge`,
				payload,
				{
					headers: { "Content-Type": "application/json" },
				}
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["hackathonCandidates", selectedTab]);
		},
	});

	useUpdateEffect(() => {
		if (documentVisibility === "visible") {
			debouncedInvalidateQueries();
		}
	}, [documentVisibility]);

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	const filteredCandidates = useMemo(() => {
		if (!hackathonCandidates) return [];

		return hackathonCandidates.filter((candidate) => {
			if (searchTerm && !candidate.email.includes(searchTerm)) {
				return false;
			}

			if (selectedTab === "candidates" && candidate.userType !== "CANDIDATE") {
				return false;
			}

			if (selectedTab === "visitors" && candidate.userType !== "VISITOR") {
				return false;
			}

			return true;
		});
	}, [hackathonCandidates, selectedTab, searchTerm]);

	return (
		<div className="flex bg-red-[#F3F3F3]">
			<Sidebar
				isOpen={isOpen}
				toggleSidebar={toggleSidebar}
				navItems={navItems}
			/>
			<div className="flex-grow">
				<Navbar toggleSidebar={toggleSidebar} title={navbarTitle} />
				<main className="pb-32 space-y-6 sm:p-5 md:pb-5">
					<Toaster position="bottom-center" />

					{/* Styled EmailImporter with user type dropdown moved inside component */}
					<div className="p-6 bg-white rounded-lg shadow-md">
						<EmailImporter
							onSubmit={handleSubmitEmails}
							submissionType={selectedUserType}
							selectedUserType={selectedUserType}
							setSelectedUserType={setSelectedUserType}
						/>
					</div>

					<div className="flex items-center justify-between">
						{/*<StatusTabs*/}
						{/*	selectedTab={selectedTab}*/}
						{/*	setSelectedTab={setSelectedTab}*/}
						{/*/>*/}

						{/*<div className="flex gap-3">*/}
						{/*<SearchBar onSearch={setSearchTerm} />*/}
						{/*<RefreshButton onRefresh={refetch} />*/}
						{/*<ColumnToggle*/}
						{/*	visibleColumns={visibleColumns}*/}
						{/*	setVisibleColumns={setVisibleColumns}*/}
						{/*/>*/}
						{/*</div>*/}
					</div>
					<CandidatesTable
						data={filteredCandidates}
						isLoading={isLoading}
						visibleColumns={visibleColumns}
						validateCandidateMutation={validateCandidate.mutate}
						invalidateCandidateMutation={invalidateCandidate.mutate}
						rooms={rooms}
						isLoadingRooms={isLoadingRooms}
						changeRoomMutation={changeRoomMutation}
						sendBadgeMutation={sendBadgeMutation}
					/>
					{/*<JudgesManager />*/}
				</main>
			</div>
		</div>
	);
};

export default HackathonScreen;
