/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as IndexImport } from './routes/index'
import { Route as mainStartgreenByIpticImport } from './routes/(main)/startgreen-by-iptic'
import { Route as mainStudioIndexImport } from './routes/(main)/studio/index'
import { Route as mainStartgreenIndexImport } from './routes/(main)/startgreen/index'
import { Route as mainStartgreenByIpticIndexImport } from './routes/(main)/startgreen-by-iptic/index'
import { Route as mainSpotifyIndexImport } from './routes/(main)/spotify/index'
import { Route as mainFaqIndexImport } from './routes/(main)/faq/index'
import { Route as mainContactIndexImport } from './routes/(main)/contact/index'
import { Route as mainCoachesIndexImport } from './routes/(main)/coaches/index'
import { Route as mainCguIndexImport } from './routes/(main)/cgu/index'
import { Route as mainAboutIndexImport } from './routes/(main)/about/index'
import { Route as mainStartgreenSplatImport } from './routes/(main)/startgreen/$'
import { Route as mainStartgreenByIpticDashboardImport } from './routes/(main)/startgreen-by-iptic/dashboard'
import { Route as mainSpotifySplatImport } from './routes/(main)/spotify/$'
import { Route as mainStartgreenByIpticLoginIndexImport } from './routes/(main)/startgreen-by-iptic/login/index'
import { Route as mainStartgreenByIpticDashboardIndexImport } from './routes/(main)/startgreen-by-iptic/dashboard/index'
import { Route as mainStartgreenByIpticApplyIndexImport } from './routes/(main)/startgreen-by-iptic/apply/index'
import { Route as mainSolutionsStartupIndexImport } from './routes/(main)/solutions/startup/index'
import { Route as mainSolutionsResearcherIndexImport } from './routes/(main)/solutions/researcher/index'
import { Route as mainSolutionsEnterpriseIndexImport } from './routes/(main)/solutions/enterprise/index'
import { Route as mainSolutionsEmployabilityIndexImport } from './routes/(main)/solutions/employability/index'
import { Route as mainStartgreenByIpticConfirmCidImport } from './routes/(main)/startgreen-by-iptic/confirm/$cid'
import { Route as mainStartgreenByIpticDashboardVisitorsIndexImport } from './routes/(main)/startgreen-by-iptic/dashboard/visitors/index'
import { Route as mainStartgreenByIpticDashboardSettingsIndexImport } from './routes/(main)/startgreen-by-iptic/dashboard/settings/index'
import { Route as mainStartgreenByIpticDashboardPhasesIndexImport } from './routes/(main)/startgreen-by-iptic/dashboard/phases/index'
import { Route as mainStartgreenByIpticDashboardParticipantsIndexImport } from './routes/(main)/startgreen-by-iptic/dashboard/participants/index'
import { Route as mainStartgreenByIpticDashboardMailBuilderIndexImport } from './routes/(main)/startgreen-by-iptic/dashboard/mail-builder/index'
import { Route as mainStartgreenByIpticDashboardJudgesIndexImport } from './routes/(main)/startgreen-by-iptic/dashboard/judges/index'
import { Route as mainStartgreenByIpticDashboardHackathonIndexImport } from './routes/(main)/startgreen-by-iptic/dashboard/hackathon/index'
import { Route as mainStartgreenByIpticDashboardEmailsIndexImport } from './routes/(main)/startgreen-by-iptic/dashboard/emails/index'
import { Route as mainStartgreenByIpticDashboardAdminsIndexImport } from './routes/(main)/startgreen-by-iptic/dashboard/admins/index'
import { Route as mainStartgreenByIpticDashboardParticipantsCidImport } from './routes/(main)/startgreen-by-iptic/dashboard/participants/$cid'
import { Route as mainStartgreenByIpticDashboardHackathonCidImport } from './routes/(main)/startgreen-by-iptic/dashboard/hackathon/$cid'

// Create/Update Routes

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const mainStartgreenByIpticRoute = mainStartgreenByIpticImport.update({
  id: '/(main)/startgreen-by-iptic',
  path: '/startgreen-by-iptic',
  getParentRoute: () => rootRoute,
} as any)

const mainStudioIndexRoute = mainStudioIndexImport.update({
  id: '/(main)/studio/',
  path: '/studio/',
  getParentRoute: () => rootRoute,
} as any)

const mainStartgreenIndexRoute = mainStartgreenIndexImport.update({
  id: '/(main)/startgreen/',
  path: '/startgreen/',
  getParentRoute: () => rootRoute,
} as any)

const mainStartgreenByIpticIndexRoute = mainStartgreenByIpticIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => mainStartgreenByIpticRoute,
  } as any,
)

const mainSpotifyIndexRoute = mainSpotifyIndexImport.update({
  id: '/(main)/spotify/',
  path: '/spotify/',
  getParentRoute: () => rootRoute,
} as any)

const mainFaqIndexRoute = mainFaqIndexImport.update({
  id: '/(main)/faq/',
  path: '/faq/',
  getParentRoute: () => rootRoute,
} as any)

const mainContactIndexRoute = mainContactIndexImport.update({
  id: '/(main)/contact/',
  path: '/contact/',
  getParentRoute: () => rootRoute,
} as any)

const mainCoachesIndexRoute = mainCoachesIndexImport.update({
  id: '/(main)/coaches/',
  path: '/coaches/',
  getParentRoute: () => rootRoute,
} as any)

const mainCguIndexRoute = mainCguIndexImport.update({
  id: '/(main)/cgu/',
  path: '/cgu/',
  getParentRoute: () => rootRoute,
} as any)

const mainAboutIndexRoute = mainAboutIndexImport.update({
  id: '/(main)/about/',
  path: '/about/',
  getParentRoute: () => rootRoute,
} as any)

const mainStartgreenSplatRoute = mainStartgreenSplatImport.update({
  id: '/(main)/startgreen/$',
  path: '/startgreen/$',
  getParentRoute: () => rootRoute,
} as any)

const mainStartgreenByIpticDashboardRoute =
  mainStartgreenByIpticDashboardImport.update({
    id: '/dashboard',
    path: '/dashboard',
    getParentRoute: () => mainStartgreenByIpticRoute,
  } as any)

const mainSpotifySplatRoute = mainSpotifySplatImport.update({
  id: '/(main)/spotify/$',
  path: '/spotify/$',
  getParentRoute: () => rootRoute,
} as any)

const mainStartgreenByIpticLoginIndexRoute =
  mainStartgreenByIpticLoginIndexImport.update({
    id: '/login/',
    path: '/login/',
    getParentRoute: () => mainStartgreenByIpticRoute,
  } as any)

const mainStartgreenByIpticDashboardIndexRoute =
  mainStartgreenByIpticDashboardIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

const mainStartgreenByIpticApplyIndexRoute =
  mainStartgreenByIpticApplyIndexImport.update({
    id: '/apply/',
    path: '/apply/',
    getParentRoute: () => mainStartgreenByIpticRoute,
  } as any)

const mainSolutionsStartupIndexRoute = mainSolutionsStartupIndexImport.update({
  id: '/(main)/solutions/startup/',
  path: '/solutions/startup/',
  getParentRoute: () => rootRoute,
} as any)

const mainSolutionsResearcherIndexRoute =
  mainSolutionsResearcherIndexImport.update({
    id: '/(main)/solutions/researcher/',
    path: '/solutions/researcher/',
    getParentRoute: () => rootRoute,
  } as any)

const mainSolutionsEnterpriseIndexRoute =
  mainSolutionsEnterpriseIndexImport.update({
    id: '/(main)/solutions/enterprise/',
    path: '/solutions/enterprise/',
    getParentRoute: () => rootRoute,
  } as any)

const mainSolutionsEmployabilityIndexRoute =
  mainSolutionsEmployabilityIndexImport.update({
    id: '/(main)/solutions/employability/',
    path: '/solutions/employability/',
    getParentRoute: () => rootRoute,
  } as any)

const mainStartgreenByIpticConfirmCidRoute =
  mainStartgreenByIpticConfirmCidImport.update({
    id: '/confirm/$cid',
    path: '/confirm/$cid',
    getParentRoute: () => mainStartgreenByIpticRoute,
  } as any)

const mainStartgreenByIpticDashboardVisitorsIndexRoute =
  mainStartgreenByIpticDashboardVisitorsIndexImport.update({
    id: '/visitors/',
    path: '/visitors/',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

const mainStartgreenByIpticDashboardSettingsIndexRoute =
  mainStartgreenByIpticDashboardSettingsIndexImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

const mainStartgreenByIpticDashboardPhasesIndexRoute =
  mainStartgreenByIpticDashboardPhasesIndexImport.update({
    id: '/phases/',
    path: '/phases/',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

const mainStartgreenByIpticDashboardParticipantsIndexRoute =
  mainStartgreenByIpticDashboardParticipantsIndexImport.update({
    id: '/participants/',
    path: '/participants/',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

const mainStartgreenByIpticDashboardMailBuilderIndexRoute =
  mainStartgreenByIpticDashboardMailBuilderIndexImport.update({
    id: '/mail-builder/',
    path: '/mail-builder/',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

const mainStartgreenByIpticDashboardJudgesIndexRoute =
  mainStartgreenByIpticDashboardJudgesIndexImport.update({
    id: '/judges/',
    path: '/judges/',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

const mainStartgreenByIpticDashboardHackathonIndexRoute =
  mainStartgreenByIpticDashboardHackathonIndexImport.update({
    id: '/hackathon/',
    path: '/hackathon/',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

const mainStartgreenByIpticDashboardEmailsIndexRoute =
  mainStartgreenByIpticDashboardEmailsIndexImport.update({
    id: '/emails/',
    path: '/emails/',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

const mainStartgreenByIpticDashboardAdminsIndexRoute =
  mainStartgreenByIpticDashboardAdminsIndexImport.update({
    id: '/admins/',
    path: '/admins/',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

const mainStartgreenByIpticDashboardParticipantsCidRoute =
  mainStartgreenByIpticDashboardParticipantsCidImport.update({
    id: '/participants/$cid',
    path: '/participants/$cid',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

const mainStartgreenByIpticDashboardHackathonCidRoute =
  mainStartgreenByIpticDashboardHackathonCidImport.update({
    id: '/hackathon/$cid',
    path: '/hackathon/$cid',
    getParentRoute: () => mainStartgreenByIpticDashboardRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/startgreen-by-iptic': {
      id: '/(main)/startgreen-by-iptic'
      path: '/startgreen-by-iptic'
      fullPath: '/startgreen-by-iptic'
      preLoaderRoute: typeof mainStartgreenByIpticImport
      parentRoute: typeof rootRoute
    }
    '/(main)/spotify/$': {
      id: '/(main)/spotify/$'
      path: '/spotify/$'
      fullPath: '/spotify/$'
      preLoaderRoute: typeof mainSpotifySplatImport
      parentRoute: typeof rootRoute
    }
    '/(main)/startgreen-by-iptic/dashboard': {
      id: '/(main)/startgreen-by-iptic/dashboard'
      path: '/dashboard'
      fullPath: '/startgreen-by-iptic/dashboard'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardImport
      parentRoute: typeof mainStartgreenByIpticImport
    }
    '/(main)/startgreen/$': {
      id: '/(main)/startgreen/$'
      path: '/startgreen/$'
      fullPath: '/startgreen/$'
      preLoaderRoute: typeof mainStartgreenSplatImport
      parentRoute: typeof rootRoute
    }
    '/(main)/about/': {
      id: '/(main)/about/'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof mainAboutIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/cgu/': {
      id: '/(main)/cgu/'
      path: '/cgu'
      fullPath: '/cgu'
      preLoaderRoute: typeof mainCguIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/coaches/': {
      id: '/(main)/coaches/'
      path: '/coaches'
      fullPath: '/coaches'
      preLoaderRoute: typeof mainCoachesIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/contact/': {
      id: '/(main)/contact/'
      path: '/contact'
      fullPath: '/contact'
      preLoaderRoute: typeof mainContactIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/faq/': {
      id: '/(main)/faq/'
      path: '/faq'
      fullPath: '/faq'
      preLoaderRoute: typeof mainFaqIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/spotify/': {
      id: '/(main)/spotify/'
      path: '/spotify'
      fullPath: '/spotify'
      preLoaderRoute: typeof mainSpotifyIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/startgreen-by-iptic/': {
      id: '/(main)/startgreen-by-iptic/'
      path: '/'
      fullPath: '/startgreen-by-iptic/'
      preLoaderRoute: typeof mainStartgreenByIpticIndexImport
      parentRoute: typeof mainStartgreenByIpticImport
    }
    '/(main)/startgreen/': {
      id: '/(main)/startgreen/'
      path: '/startgreen'
      fullPath: '/startgreen'
      preLoaderRoute: typeof mainStartgreenIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/studio/': {
      id: '/(main)/studio/'
      path: '/studio'
      fullPath: '/studio'
      preLoaderRoute: typeof mainStudioIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/startgreen-by-iptic/confirm/$cid': {
      id: '/(main)/startgreen-by-iptic/confirm/$cid'
      path: '/confirm/$cid'
      fullPath: '/startgreen-by-iptic/confirm/$cid'
      preLoaderRoute: typeof mainStartgreenByIpticConfirmCidImport
      parentRoute: typeof mainStartgreenByIpticImport
    }
    '/(main)/solutions/employability/': {
      id: '/(main)/solutions/employability/'
      path: '/solutions/employability'
      fullPath: '/solutions/employability'
      preLoaderRoute: typeof mainSolutionsEmployabilityIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/solutions/enterprise/': {
      id: '/(main)/solutions/enterprise/'
      path: '/solutions/enterprise'
      fullPath: '/solutions/enterprise'
      preLoaderRoute: typeof mainSolutionsEnterpriseIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/solutions/researcher/': {
      id: '/(main)/solutions/researcher/'
      path: '/solutions/researcher'
      fullPath: '/solutions/researcher'
      preLoaderRoute: typeof mainSolutionsResearcherIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/solutions/startup/': {
      id: '/(main)/solutions/startup/'
      path: '/solutions/startup'
      fullPath: '/solutions/startup'
      preLoaderRoute: typeof mainSolutionsStartupIndexImport
      parentRoute: typeof rootRoute
    }
    '/(main)/startgreen-by-iptic/apply/': {
      id: '/(main)/startgreen-by-iptic/apply/'
      path: '/apply'
      fullPath: '/startgreen-by-iptic/apply'
      preLoaderRoute: typeof mainStartgreenByIpticApplyIndexImport
      parentRoute: typeof mainStartgreenByIpticImport
    }
    '/(main)/startgreen-by-iptic/dashboard/': {
      id: '/(main)/startgreen-by-iptic/dashboard/'
      path: '/'
      fullPath: '/startgreen-by-iptic/dashboard/'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardIndexImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
    '/(main)/startgreen-by-iptic/login/': {
      id: '/(main)/startgreen-by-iptic/login/'
      path: '/login'
      fullPath: '/startgreen-by-iptic/login'
      preLoaderRoute: typeof mainStartgreenByIpticLoginIndexImport
      parentRoute: typeof mainStartgreenByIpticImport
    }
    '/(main)/startgreen-by-iptic/dashboard/hackathon/$cid': {
      id: '/(main)/startgreen-by-iptic/dashboard/hackathon/$cid'
      path: '/hackathon/$cid'
      fullPath: '/startgreen-by-iptic/dashboard/hackathon/$cid'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardHackathonCidImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
    '/(main)/startgreen-by-iptic/dashboard/participants/$cid': {
      id: '/(main)/startgreen-by-iptic/dashboard/participants/$cid'
      path: '/participants/$cid'
      fullPath: '/startgreen-by-iptic/dashboard/participants/$cid'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardParticipantsCidImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
    '/(main)/startgreen-by-iptic/dashboard/admins/': {
      id: '/(main)/startgreen-by-iptic/dashboard/admins/'
      path: '/admins'
      fullPath: '/startgreen-by-iptic/dashboard/admins'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardAdminsIndexImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
    '/(main)/startgreen-by-iptic/dashboard/emails/': {
      id: '/(main)/startgreen-by-iptic/dashboard/emails/'
      path: '/emails'
      fullPath: '/startgreen-by-iptic/dashboard/emails'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardEmailsIndexImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
    '/(main)/startgreen-by-iptic/dashboard/hackathon/': {
      id: '/(main)/startgreen-by-iptic/dashboard/hackathon/'
      path: '/hackathon'
      fullPath: '/startgreen-by-iptic/dashboard/hackathon'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardHackathonIndexImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
    '/(main)/startgreen-by-iptic/dashboard/judges/': {
      id: '/(main)/startgreen-by-iptic/dashboard/judges/'
      path: '/judges'
      fullPath: '/startgreen-by-iptic/dashboard/judges'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardJudgesIndexImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
    '/(main)/startgreen-by-iptic/dashboard/mail-builder/': {
      id: '/(main)/startgreen-by-iptic/dashboard/mail-builder/'
      path: '/mail-builder'
      fullPath: '/startgreen-by-iptic/dashboard/mail-builder'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardMailBuilderIndexImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
    '/(main)/startgreen-by-iptic/dashboard/participants/': {
      id: '/(main)/startgreen-by-iptic/dashboard/participants/'
      path: '/participants'
      fullPath: '/startgreen-by-iptic/dashboard/participants'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardParticipantsIndexImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
    '/(main)/startgreen-by-iptic/dashboard/phases/': {
      id: '/(main)/startgreen-by-iptic/dashboard/phases/'
      path: '/phases'
      fullPath: '/startgreen-by-iptic/dashboard/phases'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardPhasesIndexImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
    '/(main)/startgreen-by-iptic/dashboard/settings/': {
      id: '/(main)/startgreen-by-iptic/dashboard/settings/'
      path: '/settings'
      fullPath: '/startgreen-by-iptic/dashboard/settings'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardSettingsIndexImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
    '/(main)/startgreen-by-iptic/dashboard/visitors/': {
      id: '/(main)/startgreen-by-iptic/dashboard/visitors/'
      path: '/visitors'
      fullPath: '/startgreen-by-iptic/dashboard/visitors'
      preLoaderRoute: typeof mainStartgreenByIpticDashboardVisitorsIndexImport
      parentRoute: typeof mainStartgreenByIpticDashboardImport
    }
  }
}

// Create and export the route tree

interface mainStartgreenByIpticDashboardRouteChildren {
  mainStartgreenByIpticDashboardIndexRoute: typeof mainStartgreenByIpticDashboardIndexRoute
  mainStartgreenByIpticDashboardHackathonCidRoute: typeof mainStartgreenByIpticDashboardHackathonCidRoute
  mainStartgreenByIpticDashboardParticipantsCidRoute: typeof mainStartgreenByIpticDashboardParticipantsCidRoute
  mainStartgreenByIpticDashboardAdminsIndexRoute: typeof mainStartgreenByIpticDashboardAdminsIndexRoute
  mainStartgreenByIpticDashboardEmailsIndexRoute: typeof mainStartgreenByIpticDashboardEmailsIndexRoute
  mainStartgreenByIpticDashboardHackathonIndexRoute: typeof mainStartgreenByIpticDashboardHackathonIndexRoute
  mainStartgreenByIpticDashboardJudgesIndexRoute: typeof mainStartgreenByIpticDashboardJudgesIndexRoute
  mainStartgreenByIpticDashboardMailBuilderIndexRoute: typeof mainStartgreenByIpticDashboardMailBuilderIndexRoute
  mainStartgreenByIpticDashboardParticipantsIndexRoute: typeof mainStartgreenByIpticDashboardParticipantsIndexRoute
  mainStartgreenByIpticDashboardPhasesIndexRoute: typeof mainStartgreenByIpticDashboardPhasesIndexRoute
  mainStartgreenByIpticDashboardSettingsIndexRoute: typeof mainStartgreenByIpticDashboardSettingsIndexRoute
  mainStartgreenByIpticDashboardVisitorsIndexRoute: typeof mainStartgreenByIpticDashboardVisitorsIndexRoute
}

const mainStartgreenByIpticDashboardRouteChildren: mainStartgreenByIpticDashboardRouteChildren =
  {
    mainStartgreenByIpticDashboardIndexRoute:
      mainStartgreenByIpticDashboardIndexRoute,
    mainStartgreenByIpticDashboardHackathonCidRoute:
      mainStartgreenByIpticDashboardHackathonCidRoute,
    mainStartgreenByIpticDashboardParticipantsCidRoute:
      mainStartgreenByIpticDashboardParticipantsCidRoute,
    mainStartgreenByIpticDashboardAdminsIndexRoute:
      mainStartgreenByIpticDashboardAdminsIndexRoute,
    mainStartgreenByIpticDashboardEmailsIndexRoute:
      mainStartgreenByIpticDashboardEmailsIndexRoute,
    mainStartgreenByIpticDashboardHackathonIndexRoute:
      mainStartgreenByIpticDashboardHackathonIndexRoute,
    mainStartgreenByIpticDashboardJudgesIndexRoute:
      mainStartgreenByIpticDashboardJudgesIndexRoute,
    mainStartgreenByIpticDashboardMailBuilderIndexRoute:
      mainStartgreenByIpticDashboardMailBuilderIndexRoute,
    mainStartgreenByIpticDashboardParticipantsIndexRoute:
      mainStartgreenByIpticDashboardParticipantsIndexRoute,
    mainStartgreenByIpticDashboardPhasesIndexRoute:
      mainStartgreenByIpticDashboardPhasesIndexRoute,
    mainStartgreenByIpticDashboardSettingsIndexRoute:
      mainStartgreenByIpticDashboardSettingsIndexRoute,
    mainStartgreenByIpticDashboardVisitorsIndexRoute:
      mainStartgreenByIpticDashboardVisitorsIndexRoute,
  }

const mainStartgreenByIpticDashboardRouteWithChildren =
  mainStartgreenByIpticDashboardRoute._addFileChildren(
    mainStartgreenByIpticDashboardRouteChildren,
  )

interface mainStartgreenByIpticRouteChildren {
  mainStartgreenByIpticDashboardRoute: typeof mainStartgreenByIpticDashboardRouteWithChildren
  mainStartgreenByIpticIndexRoute: typeof mainStartgreenByIpticIndexRoute
  mainStartgreenByIpticConfirmCidRoute: typeof mainStartgreenByIpticConfirmCidRoute
  mainStartgreenByIpticApplyIndexRoute: typeof mainStartgreenByIpticApplyIndexRoute
  mainStartgreenByIpticLoginIndexRoute: typeof mainStartgreenByIpticLoginIndexRoute
}

const mainStartgreenByIpticRouteChildren: mainStartgreenByIpticRouteChildren = {
  mainStartgreenByIpticDashboardRoute:
    mainStartgreenByIpticDashboardRouteWithChildren,
  mainStartgreenByIpticIndexRoute: mainStartgreenByIpticIndexRoute,
  mainStartgreenByIpticConfirmCidRoute: mainStartgreenByIpticConfirmCidRoute,
  mainStartgreenByIpticApplyIndexRoute: mainStartgreenByIpticApplyIndexRoute,
  mainStartgreenByIpticLoginIndexRoute: mainStartgreenByIpticLoginIndexRoute,
}

const mainStartgreenByIpticRouteWithChildren =
  mainStartgreenByIpticRoute._addFileChildren(
    mainStartgreenByIpticRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/startgreen-by-iptic': typeof mainStartgreenByIpticRouteWithChildren
  '/spotify/$': typeof mainSpotifySplatRoute
  '/startgreen-by-iptic/dashboard': typeof mainStartgreenByIpticDashboardRouteWithChildren
  '/startgreen/$': typeof mainStartgreenSplatRoute
  '/about': typeof mainAboutIndexRoute
  '/cgu': typeof mainCguIndexRoute
  '/coaches': typeof mainCoachesIndexRoute
  '/contact': typeof mainContactIndexRoute
  '/faq': typeof mainFaqIndexRoute
  '/spotify': typeof mainSpotifyIndexRoute
  '/startgreen-by-iptic/': typeof mainStartgreenByIpticIndexRoute
  '/startgreen': typeof mainStartgreenIndexRoute
  '/studio': typeof mainStudioIndexRoute
  '/startgreen-by-iptic/confirm/$cid': typeof mainStartgreenByIpticConfirmCidRoute
  '/solutions/employability': typeof mainSolutionsEmployabilityIndexRoute
  '/solutions/enterprise': typeof mainSolutionsEnterpriseIndexRoute
  '/solutions/researcher': typeof mainSolutionsResearcherIndexRoute
  '/solutions/startup': typeof mainSolutionsStartupIndexRoute
  '/startgreen-by-iptic/apply': typeof mainStartgreenByIpticApplyIndexRoute
  '/startgreen-by-iptic/dashboard/': typeof mainStartgreenByIpticDashboardIndexRoute
  '/startgreen-by-iptic/login': typeof mainStartgreenByIpticLoginIndexRoute
  '/startgreen-by-iptic/dashboard/hackathon/$cid': typeof mainStartgreenByIpticDashboardHackathonCidRoute
  '/startgreen-by-iptic/dashboard/participants/$cid': typeof mainStartgreenByIpticDashboardParticipantsCidRoute
  '/startgreen-by-iptic/dashboard/admins': typeof mainStartgreenByIpticDashboardAdminsIndexRoute
  '/startgreen-by-iptic/dashboard/emails': typeof mainStartgreenByIpticDashboardEmailsIndexRoute
  '/startgreen-by-iptic/dashboard/hackathon': typeof mainStartgreenByIpticDashboardHackathonIndexRoute
  '/startgreen-by-iptic/dashboard/judges': typeof mainStartgreenByIpticDashboardJudgesIndexRoute
  '/startgreen-by-iptic/dashboard/mail-builder': typeof mainStartgreenByIpticDashboardMailBuilderIndexRoute
  '/startgreen-by-iptic/dashboard/participants': typeof mainStartgreenByIpticDashboardParticipantsIndexRoute
  '/startgreen-by-iptic/dashboard/phases': typeof mainStartgreenByIpticDashboardPhasesIndexRoute
  '/startgreen-by-iptic/dashboard/settings': typeof mainStartgreenByIpticDashboardSettingsIndexRoute
  '/startgreen-by-iptic/dashboard/visitors': typeof mainStartgreenByIpticDashboardVisitorsIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/spotify/$': typeof mainSpotifySplatRoute
  '/startgreen/$': typeof mainStartgreenSplatRoute
  '/about': typeof mainAboutIndexRoute
  '/cgu': typeof mainCguIndexRoute
  '/coaches': typeof mainCoachesIndexRoute
  '/contact': typeof mainContactIndexRoute
  '/faq': typeof mainFaqIndexRoute
  '/spotify': typeof mainSpotifyIndexRoute
  '/startgreen-by-iptic': typeof mainStartgreenByIpticIndexRoute
  '/startgreen': typeof mainStartgreenIndexRoute
  '/studio': typeof mainStudioIndexRoute
  '/startgreen-by-iptic/confirm/$cid': typeof mainStartgreenByIpticConfirmCidRoute
  '/solutions/employability': typeof mainSolutionsEmployabilityIndexRoute
  '/solutions/enterprise': typeof mainSolutionsEnterpriseIndexRoute
  '/solutions/researcher': typeof mainSolutionsResearcherIndexRoute
  '/solutions/startup': typeof mainSolutionsStartupIndexRoute
  '/startgreen-by-iptic/apply': typeof mainStartgreenByIpticApplyIndexRoute
  '/startgreen-by-iptic/dashboard': typeof mainStartgreenByIpticDashboardIndexRoute
  '/startgreen-by-iptic/login': typeof mainStartgreenByIpticLoginIndexRoute
  '/startgreen-by-iptic/dashboard/hackathon/$cid': typeof mainStartgreenByIpticDashboardHackathonCidRoute
  '/startgreen-by-iptic/dashboard/participants/$cid': typeof mainStartgreenByIpticDashboardParticipantsCidRoute
  '/startgreen-by-iptic/dashboard/admins': typeof mainStartgreenByIpticDashboardAdminsIndexRoute
  '/startgreen-by-iptic/dashboard/emails': typeof mainStartgreenByIpticDashboardEmailsIndexRoute
  '/startgreen-by-iptic/dashboard/hackathon': typeof mainStartgreenByIpticDashboardHackathonIndexRoute
  '/startgreen-by-iptic/dashboard/judges': typeof mainStartgreenByIpticDashboardJudgesIndexRoute
  '/startgreen-by-iptic/dashboard/mail-builder': typeof mainStartgreenByIpticDashboardMailBuilderIndexRoute
  '/startgreen-by-iptic/dashboard/participants': typeof mainStartgreenByIpticDashboardParticipantsIndexRoute
  '/startgreen-by-iptic/dashboard/phases': typeof mainStartgreenByIpticDashboardPhasesIndexRoute
  '/startgreen-by-iptic/dashboard/settings': typeof mainStartgreenByIpticDashboardSettingsIndexRoute
  '/startgreen-by-iptic/dashboard/visitors': typeof mainStartgreenByIpticDashboardVisitorsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/(main)/startgreen-by-iptic': typeof mainStartgreenByIpticRouteWithChildren
  '/(main)/spotify/$': typeof mainSpotifySplatRoute
  '/(main)/startgreen-by-iptic/dashboard': typeof mainStartgreenByIpticDashboardRouteWithChildren
  '/(main)/startgreen/$': typeof mainStartgreenSplatRoute
  '/(main)/about/': typeof mainAboutIndexRoute
  '/(main)/cgu/': typeof mainCguIndexRoute
  '/(main)/coaches/': typeof mainCoachesIndexRoute
  '/(main)/contact/': typeof mainContactIndexRoute
  '/(main)/faq/': typeof mainFaqIndexRoute
  '/(main)/spotify/': typeof mainSpotifyIndexRoute
  '/(main)/startgreen-by-iptic/': typeof mainStartgreenByIpticIndexRoute
  '/(main)/startgreen/': typeof mainStartgreenIndexRoute
  '/(main)/studio/': typeof mainStudioIndexRoute
  '/(main)/startgreen-by-iptic/confirm/$cid': typeof mainStartgreenByIpticConfirmCidRoute
  '/(main)/solutions/employability/': typeof mainSolutionsEmployabilityIndexRoute
  '/(main)/solutions/enterprise/': typeof mainSolutionsEnterpriseIndexRoute
  '/(main)/solutions/researcher/': typeof mainSolutionsResearcherIndexRoute
  '/(main)/solutions/startup/': typeof mainSolutionsStartupIndexRoute
  '/(main)/startgreen-by-iptic/apply/': typeof mainStartgreenByIpticApplyIndexRoute
  '/(main)/startgreen-by-iptic/dashboard/': typeof mainStartgreenByIpticDashboardIndexRoute
  '/(main)/startgreen-by-iptic/login/': typeof mainStartgreenByIpticLoginIndexRoute
  '/(main)/startgreen-by-iptic/dashboard/hackathon/$cid': typeof mainStartgreenByIpticDashboardHackathonCidRoute
  '/(main)/startgreen-by-iptic/dashboard/participants/$cid': typeof mainStartgreenByIpticDashboardParticipantsCidRoute
  '/(main)/startgreen-by-iptic/dashboard/admins/': typeof mainStartgreenByIpticDashboardAdminsIndexRoute
  '/(main)/startgreen-by-iptic/dashboard/emails/': typeof mainStartgreenByIpticDashboardEmailsIndexRoute
  '/(main)/startgreen-by-iptic/dashboard/hackathon/': typeof mainStartgreenByIpticDashboardHackathonIndexRoute
  '/(main)/startgreen-by-iptic/dashboard/judges/': typeof mainStartgreenByIpticDashboardJudgesIndexRoute
  '/(main)/startgreen-by-iptic/dashboard/mail-builder/': typeof mainStartgreenByIpticDashboardMailBuilderIndexRoute
  '/(main)/startgreen-by-iptic/dashboard/participants/': typeof mainStartgreenByIpticDashboardParticipantsIndexRoute
  '/(main)/startgreen-by-iptic/dashboard/phases/': typeof mainStartgreenByIpticDashboardPhasesIndexRoute
  '/(main)/startgreen-by-iptic/dashboard/settings/': typeof mainStartgreenByIpticDashboardSettingsIndexRoute
  '/(main)/startgreen-by-iptic/dashboard/visitors/': typeof mainStartgreenByIpticDashboardVisitorsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/startgreen-by-iptic'
    | '/spotify/$'
    | '/startgreen-by-iptic/dashboard'
    | '/startgreen/$'
    | '/about'
    | '/cgu'
    | '/coaches'
    | '/contact'
    | '/faq'
    | '/spotify'
    | '/startgreen-by-iptic/'
    | '/startgreen'
    | '/studio'
    | '/startgreen-by-iptic/confirm/$cid'
    | '/solutions/employability'
    | '/solutions/enterprise'
    | '/solutions/researcher'
    | '/solutions/startup'
    | '/startgreen-by-iptic/apply'
    | '/startgreen-by-iptic/dashboard/'
    | '/startgreen-by-iptic/login'
    | '/startgreen-by-iptic/dashboard/hackathon/$cid'
    | '/startgreen-by-iptic/dashboard/participants/$cid'
    | '/startgreen-by-iptic/dashboard/admins'
    | '/startgreen-by-iptic/dashboard/emails'
    | '/startgreen-by-iptic/dashboard/hackathon'
    | '/startgreen-by-iptic/dashboard/judges'
    | '/startgreen-by-iptic/dashboard/mail-builder'
    | '/startgreen-by-iptic/dashboard/participants'
    | '/startgreen-by-iptic/dashboard/phases'
    | '/startgreen-by-iptic/dashboard/settings'
    | '/startgreen-by-iptic/dashboard/visitors'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/spotify/$'
    | '/startgreen/$'
    | '/about'
    | '/cgu'
    | '/coaches'
    | '/contact'
    | '/faq'
    | '/spotify'
    | '/startgreen-by-iptic'
    | '/startgreen'
    | '/studio'
    | '/startgreen-by-iptic/confirm/$cid'
    | '/solutions/employability'
    | '/solutions/enterprise'
    | '/solutions/researcher'
    | '/solutions/startup'
    | '/startgreen-by-iptic/apply'
    | '/startgreen-by-iptic/dashboard'
    | '/startgreen-by-iptic/login'
    | '/startgreen-by-iptic/dashboard/hackathon/$cid'
    | '/startgreen-by-iptic/dashboard/participants/$cid'
    | '/startgreen-by-iptic/dashboard/admins'
    | '/startgreen-by-iptic/dashboard/emails'
    | '/startgreen-by-iptic/dashboard/hackathon'
    | '/startgreen-by-iptic/dashboard/judges'
    | '/startgreen-by-iptic/dashboard/mail-builder'
    | '/startgreen-by-iptic/dashboard/participants'
    | '/startgreen-by-iptic/dashboard/phases'
    | '/startgreen-by-iptic/dashboard/settings'
    | '/startgreen-by-iptic/dashboard/visitors'
  id:
    | '__root__'
    | '/'
    | '/(main)/startgreen-by-iptic'
    | '/(main)/spotify/$'
    | '/(main)/startgreen-by-iptic/dashboard'
    | '/(main)/startgreen/$'
    | '/(main)/about/'
    | '/(main)/cgu/'
    | '/(main)/coaches/'
    | '/(main)/contact/'
    | '/(main)/faq/'
    | '/(main)/spotify/'
    | '/(main)/startgreen-by-iptic/'
    | '/(main)/startgreen/'
    | '/(main)/studio/'
    | '/(main)/startgreen-by-iptic/confirm/$cid'
    | '/(main)/solutions/employability/'
    | '/(main)/solutions/enterprise/'
    | '/(main)/solutions/researcher/'
    | '/(main)/solutions/startup/'
    | '/(main)/startgreen-by-iptic/apply/'
    | '/(main)/startgreen-by-iptic/dashboard/'
    | '/(main)/startgreen-by-iptic/login/'
    | '/(main)/startgreen-by-iptic/dashboard/hackathon/$cid'
    | '/(main)/startgreen-by-iptic/dashboard/participants/$cid'
    | '/(main)/startgreen-by-iptic/dashboard/admins/'
    | '/(main)/startgreen-by-iptic/dashboard/emails/'
    | '/(main)/startgreen-by-iptic/dashboard/hackathon/'
    | '/(main)/startgreen-by-iptic/dashboard/judges/'
    | '/(main)/startgreen-by-iptic/dashboard/mail-builder/'
    | '/(main)/startgreen-by-iptic/dashboard/participants/'
    | '/(main)/startgreen-by-iptic/dashboard/phases/'
    | '/(main)/startgreen-by-iptic/dashboard/settings/'
    | '/(main)/startgreen-by-iptic/dashboard/visitors/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  mainStartgreenByIpticRoute: typeof mainStartgreenByIpticRouteWithChildren
  mainSpotifySplatRoute: typeof mainSpotifySplatRoute
  mainStartgreenSplatRoute: typeof mainStartgreenSplatRoute
  mainAboutIndexRoute: typeof mainAboutIndexRoute
  mainCguIndexRoute: typeof mainCguIndexRoute
  mainCoachesIndexRoute: typeof mainCoachesIndexRoute
  mainContactIndexRoute: typeof mainContactIndexRoute
  mainFaqIndexRoute: typeof mainFaqIndexRoute
  mainSpotifyIndexRoute: typeof mainSpotifyIndexRoute
  mainStartgreenIndexRoute: typeof mainStartgreenIndexRoute
  mainStudioIndexRoute: typeof mainStudioIndexRoute
  mainSolutionsEmployabilityIndexRoute: typeof mainSolutionsEmployabilityIndexRoute
  mainSolutionsEnterpriseIndexRoute: typeof mainSolutionsEnterpriseIndexRoute
  mainSolutionsResearcherIndexRoute: typeof mainSolutionsResearcherIndexRoute
  mainSolutionsStartupIndexRoute: typeof mainSolutionsStartupIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  mainStartgreenByIpticRoute: mainStartgreenByIpticRouteWithChildren,
  mainSpotifySplatRoute: mainSpotifySplatRoute,
  mainStartgreenSplatRoute: mainStartgreenSplatRoute,
  mainAboutIndexRoute: mainAboutIndexRoute,
  mainCguIndexRoute: mainCguIndexRoute,
  mainCoachesIndexRoute: mainCoachesIndexRoute,
  mainContactIndexRoute: mainContactIndexRoute,
  mainFaqIndexRoute: mainFaqIndexRoute,
  mainSpotifyIndexRoute: mainSpotifyIndexRoute,
  mainStartgreenIndexRoute: mainStartgreenIndexRoute,
  mainStudioIndexRoute: mainStudioIndexRoute,
  mainSolutionsEmployabilityIndexRoute: mainSolutionsEmployabilityIndexRoute,
  mainSolutionsEnterpriseIndexRoute: mainSolutionsEnterpriseIndexRoute,
  mainSolutionsResearcherIndexRoute: mainSolutionsResearcherIndexRoute,
  mainSolutionsStartupIndexRoute: mainSolutionsStartupIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/(main)/startgreen-by-iptic",
        "/(main)/spotify/$",
        "/(main)/startgreen/$",
        "/(main)/about/",
        "/(main)/cgu/",
        "/(main)/coaches/",
        "/(main)/contact/",
        "/(main)/faq/",
        "/(main)/spotify/",
        "/(main)/startgreen/",
        "/(main)/studio/",
        "/(main)/solutions/employability/",
        "/(main)/solutions/enterprise/",
        "/(main)/solutions/researcher/",
        "/(main)/solutions/startup/"
      ]
    },
    "/": {
      "filePath": "index.ts"
    },
    "/(main)/startgreen-by-iptic": {
      "filePath": "(main)/startgreen-by-iptic.tsx",
      "children": [
        "/(main)/startgreen-by-iptic/dashboard",
        "/(main)/startgreen-by-iptic/",
        "/(main)/startgreen-by-iptic/confirm/$cid",
        "/(main)/startgreen-by-iptic/apply/",
        "/(main)/startgreen-by-iptic/login/"
      ]
    },
    "/(main)/spotify/$": {
      "filePath": "(main)/spotify/$.tsx"
    },
    "/(main)/startgreen-by-iptic/dashboard": {
      "filePath": "(main)/startgreen-by-iptic/dashboard.tsx",
      "parent": "/(main)/startgreen-by-iptic",
      "children": [
        "/(main)/startgreen-by-iptic/dashboard/",
        "/(main)/startgreen-by-iptic/dashboard/hackathon/$cid",
        "/(main)/startgreen-by-iptic/dashboard/participants/$cid",
        "/(main)/startgreen-by-iptic/dashboard/admins/",
        "/(main)/startgreen-by-iptic/dashboard/emails/",
        "/(main)/startgreen-by-iptic/dashboard/hackathon/",
        "/(main)/startgreen-by-iptic/dashboard/judges/",
        "/(main)/startgreen-by-iptic/dashboard/mail-builder/",
        "/(main)/startgreen-by-iptic/dashboard/participants/",
        "/(main)/startgreen-by-iptic/dashboard/phases/",
        "/(main)/startgreen-by-iptic/dashboard/settings/",
        "/(main)/startgreen-by-iptic/dashboard/visitors/"
      ]
    },
    "/(main)/startgreen/$": {
      "filePath": "(main)/startgreen/$.tsx"
    },
    "/(main)/about/": {
      "filePath": "(main)/about/index.tsx"
    },
    "/(main)/cgu/": {
      "filePath": "(main)/cgu/index.tsx"
    },
    "/(main)/coaches/": {
      "filePath": "(main)/coaches/index.tsx"
    },
    "/(main)/contact/": {
      "filePath": "(main)/contact/index.tsx"
    },
    "/(main)/faq/": {
      "filePath": "(main)/faq/index.tsx"
    },
    "/(main)/spotify/": {
      "filePath": "(main)/spotify/index.tsx"
    },
    "/(main)/startgreen-by-iptic/": {
      "filePath": "(main)/startgreen-by-iptic/index.tsx",
      "parent": "/(main)/startgreen-by-iptic"
    },
    "/(main)/startgreen/": {
      "filePath": "(main)/startgreen/index.tsx"
    },
    "/(main)/studio/": {
      "filePath": "(main)/studio/index.tsx"
    },
    "/(main)/startgreen-by-iptic/confirm/$cid": {
      "filePath": "(main)/startgreen-by-iptic/confirm/$cid.tsx",
      "parent": "/(main)/startgreen-by-iptic"
    },
    "/(main)/solutions/employability/": {
      "filePath": "(main)/solutions/employability/index.tsx"
    },
    "/(main)/solutions/enterprise/": {
      "filePath": "(main)/solutions/enterprise/index.tsx"
    },
    "/(main)/solutions/researcher/": {
      "filePath": "(main)/solutions/researcher/index.tsx"
    },
    "/(main)/solutions/startup/": {
      "filePath": "(main)/solutions/startup/index.tsx"
    },
    "/(main)/startgreen-by-iptic/apply/": {
      "filePath": "(main)/startgreen-by-iptic/apply/index.tsx",
      "parent": "/(main)/startgreen-by-iptic"
    },
    "/(main)/startgreen-by-iptic/dashboard/": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/index.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    },
    "/(main)/startgreen-by-iptic/login/": {
      "filePath": "(main)/startgreen-by-iptic/login/index.tsx",
      "parent": "/(main)/startgreen-by-iptic"
    },
    "/(main)/startgreen-by-iptic/dashboard/hackathon/$cid": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/hackathon/$cid.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    },
    "/(main)/startgreen-by-iptic/dashboard/participants/$cid": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/participants/$cid.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    },
    "/(main)/startgreen-by-iptic/dashboard/admins/": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/admins/index.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    },
    "/(main)/startgreen-by-iptic/dashboard/emails/": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/emails/index.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    },
    "/(main)/startgreen-by-iptic/dashboard/hackathon/": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/hackathon/index.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    },
    "/(main)/startgreen-by-iptic/dashboard/judges/": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/judges/index.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    },
    "/(main)/startgreen-by-iptic/dashboard/mail-builder/": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/mail-builder/index.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    },
    "/(main)/startgreen-by-iptic/dashboard/participants/": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/participants/index.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    },
    "/(main)/startgreen-by-iptic/dashboard/phases/": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/phases/index.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    },
    "/(main)/startgreen-by-iptic/dashboard/settings/": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/settings/index.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    },
    "/(main)/startgreen-by-iptic/dashboard/visitors/": {
      "filePath": "(main)/startgreen-by-iptic/dashboard/visitors/index.tsx",
      "parent": "/(main)/startgreen-by-iptic/dashboard"
    }
  }
}
ROUTE_MANIFEST_END */
