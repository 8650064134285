import React from "react";
import { useNavigate, useLocation } from "@tanstack/react-router";
import useAuth from "@core/guards/auth.util.ts";
import { navItems } from "../../pages/(startgreen)/views/(dashboard)/Sidebar/navitems";
import { log } from "console";

interface RoleGuardProps {
	requiredRole: string;
	children: React.ReactNode;
}

const getCommonPrefixLength = (a: string, b: string) => {
	let i = 0;
	while (i < a.length && i < b.length && a[i] === b[i]) {
		i++;
	}
	return i;
};

const RoleGuard: React.FC<RoleGuardProps> = ({ requiredRole, children }) => {
	const { isAuthenticated, role, isAuthLoading } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
  
	React.useEffect(() => {
		if (!isAuthLoading) {
			// Determine allowed routes for the current role from navItems.
			const allowedRoutes = navItems.filter((item) =>
				item.roles.includes(role)
			);
			const currentPath = location.pathname;
			// Check if current path is allowed (supports nested paths).
			const isAllowed = allowedRoutes.some(
				(item) =>
					currentPath === item.path || currentPath.startsWith(item.path + "/")
			);
			if (isAllowed) return;

			// Find the allowed route with the longest matching prefix.
			let bestRoute = allowedRoutes[0];
			let bestPrefix = 0;
			allowedRoutes.forEach((item) => {
				if (
					currentPath === item.path ||
					currentPath.startsWith(item.path + "/")
				) {
					if (item.path.length > bestPrefix) {
						bestPrefix = item.path.length;
						bestRoute = item;
					}
				}
			});
			navigate({
				to: bestRoute.path,
			});
		}
	}, [isAuthLoading, isAuthenticated, role, requiredRole, navigate, location]);
  
	return isAuthenticated ? <>{children}</> : null;
};

export default RoleGuard;
