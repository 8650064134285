import { createFileRoute, Navigate } from "@tanstack/react-router";
import { useLocation } from "@tanstack/react-router";

export const Route = createFileRoute("/(main)/startgreen/")({
	component: () => {
		const location = useLocation();
		
		// Ensure we only replace the exact path segment
		const targetPath = "/startgreen-by-iptic";
		const currentPath = location.pathname;
		
		// Check if already redirected to avoid duplication
		if (currentPath.includes(targetPath)) {
			return <Navigate to={currentPath} />;
		}
		
		// Replace only the exact path segment
		const newPath = currentPath.replace(/\/startgreen(\/|$)/, `${targetPath}$1`);
		console.log("newPath", newPath);

		return <Navigate to={newPath} />;
	},
});
