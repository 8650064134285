import React, { useState, useEffect } from "react";
import { FaArrowAltCircleLeft, FaQrcode, FaSpinner, FaTimes } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import { decryptQrBadge } from "@api/service/startgreen.services.ts";
import toast, { Toaster } from "react-hot-toast";
import { useDebounce } from "ahooks";
import { Loader2, LogOut, QrCode, RefreshCcw, X } from "lucide-react";
import QRScannerCam from "@pages/(startgreen)/views/(settings)/QrScannerCam.tsx";

interface QRScannerProps {
	isOpen?: boolean;
	onClose?: () => void;
}

const QRScanner: React.FC<QRScannerProps> = ({ isOpen = false, onClose }) => {
	const [isScannerActive, setScannerActive] = useState(isOpen);
	const [scannedData, setScannedData] = useState<string | null>(null);
	const [isLoadingCustom, setIsLoadingCustom] = useState(false);
	const [loadingToastId, setLoadingToastId] = useState<string | null>(null);

	// Update scanner state when isOpen prop changes
	useEffect(() => {
		setScannerActive(isOpen);
	}, [isOpen]);

	const debouncedScannedData = useDebounce(scannedData, { wait: 1000 });

	const { mutate, isLoading, isError, isSuccess, error, data } = useMutation({
		mutationFn: decryptQrBadge,
		onSuccess: (response) => {
			if (loadingToastId) {
				toast.dismiss(loadingToastId);
				setLoadingToastId(null);
			}
			toast.success(response.message, { duration: 2000 });
			setIsLoadingCustom(false);
			// Remove the auto-closing behavior to keep scanner open
		},
		onError: (error) => {
			if (loadingToastId) {
				toast.dismiss(loadingToastId);
				setLoadingToastId(null);
			}
			toast.error("Erreur lors de la décryption du QR code.", {
				duration: 2000,
			});
			setIsLoadingCustom(false);
			setScannerActive(false);
			if (onClose) onClose();
		},
	});

	const handleScan = (data: string | null) => {
		// Prevent duplicate toasts if one is already active
		if (data && !loadingToastId && data !== scannedData) {
			setScannedData(data);
			setIsLoadingCustom(true);
			// Provide user feedback for successful scan and store the new toast ID
			const id = toast.loading("QR code détecté, traitement en cours...");
			setLoadingToastId(id);
		}
	};

	const handleError = (error: any) => {
		console.error("Erreur lors du scan du QR code :", error);
		if (loadingToastId) {
			toast.dismiss(loadingToastId);
		}
		toast.error("Erreur lors du scan du QR code.");
		setIsLoadingCustom(false);
	};

	const toggleScanner = () => {
		const newState = !isScannerActive;
		setScannerActive(newState);
		setScannedData(null);
		setIsLoadingCustom(false);

		// Call onClose if provided and scanner is being closed
		if (!newState && onClose) {
			onClose();
		}
	};

	// Trigger the mutation when debounced scanned data changes
	React.useEffect(() => {
		if (debouncedScannedData) {
			mutate(debouncedScannedData);
		}
	}, [debouncedScannedData, mutate]);

	return (
		<div className="flex flex-col items-center">
			{isScannerActive ? (
				<div className="fixed inset-0 z-50 flex items-center justify-center transition-all duration-75 ease-in-out bg-black/50 backdrop-blur-sm ">
					<div className="relative w-full max-w-md mx-4 overflow-hidden transition-all bg-white shadow-2xl duration-0 rounded-3xl ">
						{/* Header */}
						<div className="flex items-center justify-between px-6 py-4">
							<h3 className="text-xl font-medium text-gray-800">
								Scan QR Code
							</h3>
							<button
								className="flex items-center justify-center gap-2 px-3 py-2 text-red-700 transition-colors bg-red-100 rounded-md hover:bg-red-200"
								onClick={toggleScanner}
							>
								<X size={18} className="text-red-700" />
							</button>
						</div>

						{/* Scanner Content */}
						<div className="px-6 py-4">
							{isLoadingCustom ? (
								<div className="flex flex-col items-center justify-center py-12">
									<div className="relative w-16 h-16 mb-6">
										<div className="absolute inset-0 bg-green-200 rounded-full animate-pulse"></div>
										<Loader2 className="absolute inset-0 w-16 h-16 text-green-600 animate-spin" />
									</div>
									<p className="text-lg font-medium text-gray-700">
										Processing...
									</p>
									<p className="mt-2 text-sm text-gray-500">
										Please wait while we verify the QR code
									</p>
								</div>
							) : (
								<div className="space-y-5">
									{/* <p className="text-sm text-center text-gray-500">
										Position the QR code within the frame to scan
									</p> */}

									{/* Scanner Camera Component */}
									<QRScannerCam onScan={handleScan} onError={handleError} />

									{/* <p className="text-xs italic text-center text-gray-400">
										Make sure the QR code is well-lit and clearly visible
									</p> */}
								</div>
							)}
						</div>
						{/* Footer */}
						<div className="flex flex-col justify-end px-6 py-4 sm:flex-row">
							<button
								className="flex items-center justify-center w-full gap-2 px-3 py-2 mb-2 text-center text-green-700 transition-colors bg-green-100 rounded-md sm:w-1/2 hover:bg-green-200 sm:mb-0"
								onClick={() => {
									setScannedData(null);
									setScannerActive(true);
								}}
							>
								<RefreshCcw size={18} />
								Refresh
							</button>
							<div className="sm:w-2" />
							<button
								className="flex items-center justify-center w-full gap-2 px-3 py-2 text-center text-red-700 transition-colors bg-red-100 rounded-md sm:w-1/2 hover:bg-red-200"
								onClick={toggleScanner}
							>
								<X size={18} />
								Close Scanner
							</button>
						</div>
					</div>
				</div>
			) : (
				<button
					onClick={toggleScanner}
					className="relative px-6 py-3 overflow-hidden text-white transition-all duration-100 bg-green-500 rounded-full shadow-lg group hover:bg-green-600 button-hover-effect"
				>
					<span className="flex items-center justify-center">
						<QrCode className="w-5 h-5 mr-2" />
						<span>Scan QR Code</span>
					</span>
					<span className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white/20 to-transparent -translate-x-full group-hover:animate-[shimmer_1.5s_infinite]"></span>
				</button>
			)}
		</div>
	);
};

export default QRScanner;
