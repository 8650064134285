import React from "react";
import { useNavigate, useLocation } from "@tanstack/react-router";
import useAuth from "@core/guards/auth.util.ts";

interface AuthGuardProps {
	children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
	const { isAuthenticated, isAuthLoading , role} = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	React.useEffect(() => {
		if (!isAuthLoading && !isAuthenticated) {
			navigate({
				to: "/startgreen-by-iptic/login",
				// search: { from: location.pathname }, // Preserve the original path
			});
		}
	}, [isAuthLoading, isAuthenticated, navigate, location]);

	if (isAuthLoading) {
		return <div>Loading...</div>; // Or a spinner component
	}

	return isAuthenticated ? <>{children}</> : null;
};

export default AuthGuard;
