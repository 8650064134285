import { Link, useRouter } from "@tanstack/react-router";
import { cn } from "@core/utils";
import { navItems } from "@pages/(startgreen)/views/(dashboard)/Sidebar/navitems.ts";
import useAuth from "@core/guards/auth.util";
import Image from "@components/elements/Image";
import React from "react";

const Sidebar = ({ toggleSidebar, isOpen }: SidebarProps) => {
	const { role } = useAuth();
	const router = useRouter();
	const currentPath = router.state.location.pathname;

	return (
		<>
			<aside
				className={cn(
					role === "admin" ? "lg:block" : "hidden",
					isOpen ? "w-[50px] block" : "hidden",
					"lg:w-64 w-[70%] min-h-screen bg-white outline outline-1 outline-gray-100/10 fixed lg:relative z-50"
				)}
			>
				<h1 className="flex px-10 py-3 mb-0 text-white">
					<Image
						className="flex-shrink-0 rounded-md aspect-auto "
						width={110}
						height={110}
						src="/images/startgreen/startgreen-by.webp"
						alt="StartGreen"
						aria-label="StartGreen"
					/>
				</h1>
				<nav>
					<ul className="space-y-0">
						{navItems
								.filter(item => !item.hide && item.roles.includes(role))
								.map(item => {
								const isActive = currentPath === item.path;
								return (
									<Link to={item.path} className="block" key={item.path}>
										<li
											className={cn(
												"cursor-pointer px-10 py-4",
												isActive
													? "bg-startgreen_primary-100/10 text-green-700 font-semibold"
													: "hover:bg-gray-50 text-gray-700 hover:text-green-500",
												"outline-1 outline outline-gray-100/10"
											)}
										>
											{item.label}
										</li>
									</Link>
								);
							})}
					</ul>
					<button
						className="block p-2 m-4 text-white bg-green-600 rounded lg:hidden"
						onClick={toggleSidebar}
					>
						Close
					</button>
				</nav>
			</aside>

			{isOpen && (
				<div
					className="fixed inset-0 z-20 opacity-50 bg-black-800 lg:hidden"
					onClick={toggleSidebar}
				/>
			)}
		</>
	);
};

export default Sidebar;
