import React, { useState } from "react";
import LanguageToggle from "@components/layout/Navbar/components/LanguageToggle.tsx";
import { cn } from "@core/utils";
import useAuth from "@core/guards/auth.util.ts";
import QRScanner from "@pages/(startgreen)/views/(settings)/QRScanner.tsx";
import { LogOut, Menu, QrCode, Users } from "lucide-react";
import { useRouter } from "@tanstack/react-router";

function Navbar ({
	title = "Tableau de bord StartGreen by IPTIC",
	toggleSidebar,
}: {
	title?: string;
	toggleSidebar: () => void;
}) {
	const { handleLogout } = useAuth();
	// Add state for QR scanner visibility
	const [isQRScannerOpen, setIsQRScannerOpen] = useState(false);
	// Get current route information from TanStack Router
	const router = useRouter();
	const currentPath = router.state.location.pathname;
	// Check if current path is dashboard/hackathon
	const isHackathonPage = currentPath.includes('/dashboard/hackathon');

	// Toggle QR scanner function
	const toggleQRScanner = () => {
		setIsQRScannerOpen((prev) => !prev);
	};

	return (
		<>
			<div className="sticky top-0 z-30 flex items-center justify-between px-4 py-3 bg-white border-b border-green-100 shadow-sm">
				<div className="text-xl font-semibold text-green-800 truncate">
					{title}
				</div>
				<div className="flex items-center space-x-3">
					<button
						className="p-2 text-green-600 transition-colors rounded-md lg:hidden hover:bg-green-50"
						onClick={toggleSidebar}
					>
						<Menu size={20} />
					</button>
					<button
						className="items-center hidden gap-2 px-3 py-2 text-green-700 transition-colors bg-green-100 rounded-md md:flex hover:bg-green-200"
						onClick={toggleQRScanner}
					>
						<QrCode size={18} />
						<span className="text-sm font-medium">Scan QR</span>
					</button>
					<button className="items-center hidden gap-2 px-3 py-2 text-red-700 transition-colors bg-red-100 rounded-md md:flex hover:bg-red-200">
						<LogOut size={18} />
						<span className="text-sm font-medium" onClick={handleLogout}>
							Logout
						</span>
					</button>
				</div>
			</div>

			{/* <nav
				className={cn(
					"static top-0 bg-white shadow-md p-4 flex justify-between items-center z-10",
					"outline outline-1 outline-gray-100/10 "
				)}
			>
				<h1 className="text-xl font-semibold">{title}</h1>

				<div className="flex items-center space-x-4">
					<button
						className="block p-2 m-4 text-white bg-green-600 rounded lg:hidden"
						onClick={toggleSidebar}
					>
						Menu
					</button>
					<QRScanner />
					<button
						onClick={handleLogout}
						className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
					>
						Déconnexion
					</button>
				</div>
			</nav> */}

			{/* Only show the bottom navigation on the hackathon page */}
			{isHackathonPage && (
				<div className="fixed bottom-0 left-0 right-0 md:hidden bg-white border-t border-green-200 px-2 py-3 flex justify-around items-center shadow-[0_-2px_10px_rgba(0,0,0,0.05)] z-30">
					<button
						className="flex flex-col items-center justify-center p-2 transition-colors rounded-md hover:bg-green-50"
						onClick={toggleQRScanner}
					>
						<QrCode className="w-6 h-6 mb-1 text-green-600" />
						<span className="text-xs font-medium text-green-700">Scan QR</span>
					</button>
				</div>
			)}

			{/* Conditionally render QR Scanner */}
			{isQRScannerOpen && (
				<QRScanner
					isOpen={isQRScannerOpen}
					onClose={() => {
						setIsQRScannerOpen(false);
					}}
				/>
			)}
		</>
	);
}

export default Navbar;
