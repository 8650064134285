import React from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Button, CircularProgress, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { cn } from "@core/utils";
import FileUploader from "./FileUploader";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Tooltip } from "@pages/(startgreen)/views/(dashboard)/Tooltip.tsx";

// Update the interface to include firstName and lastName
export interface EmailEntry {
	email: string;
	firstName: string;
	lastName: string;
	userType?: string;
}

interface EmailImporterProps {
	onSubmit: (entries: EmailEntry[]) => Promise<void>;
	submissionType?: string;
	selectedUserType?: string;
	setSelectedUserType?: (type: string) => void;
}

const EmailImporter: React.FC<EmailImporterProps> = ({
	onSubmit,
	submissionType = "USER",
	selectedUserType,
	setSelectedUserType,
}) => {
	// Update state to store full entry objects instead of just emails
	const [entries, setEntries] = useState<EmailEntry[]>([
		{
			email: "",
			firstName: "",
			lastName: "",
			userType: selectedUserType || "CANDIDATE",
		},
	]);
	const [file, setFile] = useState<File | null>(null);
	const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
	const [isLoadingUpload, setIsLoadingUpload] = useState(false);

	const handleAddEntry = () => {
		setEntries((prev) => [
			...prev,
			{
				email: "",
				firstName: "",
				lastName: "",
				userType: selectedUserType || "CANDIDATE",
			},
		]);
	};

	const handleEntryChange = (
		index: number,
		field: keyof EmailEntry,
		value: string
	) => {
		const updatedEntries = [...entries];
		updatedEntries[index][field] = value;
		setEntries(updatedEntries);
	};

	const handleRemoveEntry = (index: number) => {
		setEntries((prev) => prev.filter((_, i) => i !== index));
	};

	const handleClearAll = () => {
		setEntries([
			{
				email: "",
				firstName: "",
				lastName: "",
				userType: selectedUserType || "CANDIDATE",
			},
		]);
		setFile(null);
	};

	const extractEmailsFromFile = async (file: File) => {
		const fileExtension = file.name.split(".").pop()?.toLowerCase();

		if (fileExtension === "csv") {
			const parsedData = await new Promise<any[]>((resolve, reject) => {
				Papa.parse(file, {
					header: true,
					skipEmptyLines: true,
					complete: (results) => {
						resolve(results.data);
					},
					error: (err) => {
						reject(err);
					},
				});
			});

			// Process the CSV data
			return parseFileData(parsedData);
		} else if (["xlsx", "xls"].includes(fileExtension || "")) {
			const data = await file.arrayBuffer();
			const workbook = XLSX.read(data, { type: "array" });
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];
			const sheetData = XLSX.utils.sheet_to_json(worksheet);

			// Process the Excel data
			return parseFileData(sheetData);
		} else {
			toast.error("Seuls les fichiers CSV et Excel sont acceptés.");
			return [];
		}
	};

	// Improved helper to get a value by checking if any key contains the search terms
	const getValueByKeys = (row: any, keys: string[]): string => {
		// First try exact match
		for (const key of Object.keys(row)) {
			if (keys.some((k) => key.toLowerCase() === k.toLowerCase())) {
				return row[key];
			}
		}

		// Then try partial match if no exact match is found
		for (const key of Object.keys(row)) {
			if (keys.some((k) => key.toLowerCase().includes(k.toLowerCase()))) {
				return row[key];
			}
		}

		return "";
	};

	const parseFileData = (data: any[]): EmailEntry[] => {
		return data.map((row) => {
			// Try exact matches first, then partial matches for each field
			const email = getValueByKeys(row, ["email", "mail", "e-mail"]);
			const firstName = getValueByKeys(row, [
				"first_name",
				"prénom",
				"prenom",
				"firstname",
				"first",
			]);
			const lastName = getValueByKeys(row, [
				"last_name",
				"nom",
				"lastname",
				"last",
				"surname",
				"family_name",
			]);
			return {
				email,
				firstName,
				lastName,
				userType: selectedUserType || "CANDIDATE",
			};
		});
	};

	// Update entries when user type changes
	React.useEffect(() => {
		if (selectedUserType) {
			setEntries(
				entries.map((entry) => ({
					...entry,
					userType: selectedUserType,
				}))
			);
		}
	}, [selectedUserType]);

	const handleFileUpload = async () => {
		if (file) {
			setIsLoadingUpload(true);
			try {
				const processedData = await extractEmailsFromFile(file);

				if (Array.isArray(processedData)) {
					// Filter entries with valid emails
					const validEntries = processedData.filter(
						(entry) => entry.email && entry.email.includes("@")
					);

					// Create a Set of existing emails to check for duplicates
					const existingEmails = new Set(
						entries.map((entry) => entry.email).filter(Boolean)
					);

					// Filter out duplicate entries
					const uniqueEntries = validEntries.filter(
						(entry) => !existingEmails.has(entry.email)
					);

					// Merge existing valid entries with new unique entries
					const validExistingEntries = entries.filter((entry) => entry.email);
					const mergedEntries = [...validExistingEntries, ...uniqueEntries];

					// If we have no entries, keep an empty form
					setEntries(
						mergedEntries.length > 0
							? mergedEntries
							: [
									{
										email: "",
										firstName: "",
										lastName: "",
										userType: selectedUserType || "CANDIDATE",
									},
								]
					);

					toast.success("Fichier téléchargé et traité avec succès !");
				}
			} catch (error) {
				console.error("Error processing file:", error);
				toast.error("Erreur lors du traitement du fichier.");
			} finally {
				setIsLoadingUpload(false);
				setFile(null);
			}
		}
	};

	const handleSubmit = async () => {
		const validEntries = entries.filter(
			(entry) => entry.email && entry.email.includes("@")
		);

		if (validEntries.length === 0) {
			toast.error(
				"Il doit y avoir au moins une adresse email valide avant de confirmer."
			);
			return;
		}

		setIsLoadingSubmit(true);
		try {
			// Each entry now includes userType
			await onSubmit(validEntries);
			setEntries([
				{
					email: "",
					firstName: "",
					lastName: "",
					userType: selectedUserType || "CANDIDATE",
				},
			]);
			toast.success("Invitations envoyées avec succès !");
		} catch {
			toast.error("Erreur lors de l'envoi des invitations.");
		} finally {
			setIsLoadingSubmit(false);
		}
	};

	return (
		<>
			<div className="w-full max-w-screen-lg p-6 mx-auto space-y-6 bg-white rounded-lg shadow-sm">
				<h2 className="mb-4 text-xl font-bold text-gray-800">
					Importateur d'Emails
				</h2>

				{/* Add the dropdown if props are provided */}
				{setSelectedUserType && selectedUserType && (
					<div>
						<div className="flex justify-end mb-4">
							<div className="inline-flex rounded-md shadow-sm">
								<button
									className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
										selectedUserType === "CANDIDATE"
											? "bg-green-600 text-white"
											: "bg-white text-green-700 border border-green-300 hover:bg-green-50"
									}`}
									onClick={() => {
										setSelectedUserType("CANDIDATE");
									}}
								>
									Candidate
								</button>
								<button
									className={`px-4 py-2 text-sm font-medium   ${
										selectedUserType === "VISITOR"
											? "bg-green-600 text-white"
											: "bg-white text-green-700 border border-green-300 hover:bg-green-50"
									}`}
									onClick={() => {
										setSelectedUserType("VISITOR");
									}}
								>
									Visitor
								</button>
								<button
									className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
										selectedUserType === "JUDGE"
											? "bg-green-600 text-white"
											: "bg-white text-green-700 border border-green-300 hover:bg-green-50"
									}`}
									onClick={() => {
										setSelectedUserType("JUDGE");
									}}
								>
									Judge
								</button>
							</div>
						</div>
					</div>
				)}

				{/* File Uploader Section */}
				<div className="space-y-4">
					<div className="flex flex-col items-start gap-4 md:flex-row">
						<div className="w-full">
							<FileUploader
								onFileSelect={setFile}
								disabled={isLoadingSubmit || isLoadingUpload}
							/>
						</div>
						<div className="mt-2 md:mt-0">
							<Tooltip
								title="Téléchargement de fichier"
								description="Téléchargez un fichier CSV ou Excel pour importer des adresses email."
							/>
						</div>
					</div>

					<div className="flex flex-col gap-2 sm:flex-row">
						<Button
							variant="contained"
							fullWidth
							className={cn(
								"!bg-startgreen_primary-100 !disabled:bg-startgreen_primary-100/20 disabled:!cursor-not-allowed",
								!file || isLoadingUpload
									? "cursor-not-allowed !bg-startgreen_primary-100/30"
									: ""
							)}
							onClick={handleFileUpload}
							disabled={!file || isLoadingUpload || isLoadingSubmit}
						>
							{isLoadingUpload ? (
								<CircularProgress size={24} />
							) : (
								"Importer les Emails"
							)}
						</Button>
					</div>
				</div>

				{/* Email List Section with First Name and Last Name fields */}
				<div className="mt-6">
					<div className="flex items-center justify-between mb-2">
						<h3 className="font-bold text-gray-800">
							Liste des utilisateurs à inviter
						</h3>
						<Button
							variant="outlined"
							color="error"
							size="small"
							startIcon={<DeleteOutlineIcon />}
							onClick={handleClearAll}
							disabled={entries.length === 1 && !entries[0].email}
							className="!text-sm"
						>
							Tout effacer
						</Button>
					</div>

					<div className="p-4 overflow-y-auto border rounded max-h-80 bg-gray-50">
						{entries.map((entry, index) => (
							<div
								key={index}
								className="flex flex-wrap items-center mt-2 space-x-2"
							>
								<TextField
									label="Prénom"
									value={entry.firstName}
									onChange={(e) => {
										handleEntryChange(index, "firstName", e.target.value);
									}}
									disabled={isLoadingSubmit}
									size="small"
									className="flex-grow mb-2 bg-white md:mb-0 md:flex-grow-0 md:w-1/4"
								/>
								<TextField
									label="Nom"
									value={entry.lastName}
									onChange={(e) => {
										handleEntryChange(index, "lastName", e.target.value);
									}}
									disabled={isLoadingSubmit}
									size="small"
									className="flex-grow mb-2 bg-white md:mb-0 md:flex-grow-0 md:w-1/4"
								/>
								<TextField
									label="Email"
									type="email"
									value={entry.email}
									onChange={(e) => {
										handleEntryChange(index, "email", e.target.value);
									}}
									disabled={isLoadingSubmit}
									size="small"
									className="flex-grow bg-white"
									required
								/>
								<IconButton
									color="error"
									onClick={() => {
										handleRemoveEntry(index);
									}}
									disabled={
										isLoadingSubmit || (entries.length === 1 && index === 0)
									}
									size="small"
									className="!bg-white ml-2"
								>
									<CloseIcon />
								</IconButton>
							</div>
						))}
					</div>
				</div>

				{/* Action Buttons */}
				<div className="flex flex-col gap-2 pt-4 sm:flex-row">
					<Button
						variant="outlined"
						className={cn(
							"border !border-startgreen_primary-100/30 !text-startgreen_darkblue-100 hover:!bg-startgreen_darkblue-100/5",
							"disabled:!cursor-not-allowed"
						)}
						onClick={handleAddEntry}
						disabled={isLoadingSubmit}
						fullWidth
					>
						Ajouter un utilisateur
					</Button>

					<Button
						variant="contained"
						className="!bg-startgreen_primary-100 !disabled:bg-startgreen_primary-100/30 text-white hover:!bg-opacity-90"
						onClick={handleSubmit}
						disabled={isLoadingSubmit || entries.every((entry) => !entry.email)}
						fullWidth
					>
						{isLoadingSubmit ? <CircularProgress size={24} /> : "Inviter"}
					</Button>
				</div>
			</div>
		</>
	);
};

export default EmailImporter;
