import { useState, useEffect } from "react";
import { Link } from "@tanstack/react-router";

const TableauCandidats = ({
	candidates,
	extractAnswer,
	toggleCandidateSelection,
	selectedCandidates,
	initialFields, // Initial fields array passed as a prop
	isActionTab,
}) => {
	// State to manage the dynamically selected columns
	const [fields, setFields] = useState(initialFields);
	const [availableFields] = useState([
		// "isConfirmed",
		// "hackathon_participation",
		// "professional_situation",
		// "education_level",
		{ key: "isConfirmed", label: "Confirmé" },
		{ key: "hackathon_participation", label: "Hackathon" },
		{ key: "professional_situation", label: "Situation Professionnelle" },
		{ key: "education_level", label: "Niveau d'education" },
	]);

	const formatValue = (value) => {
		if (typeof value === "boolean") {
			return value ? "Oui" : "Non";
		}
		return value || "-";
	};

	// Handle column selection changes
	const handleColumnChange = (field) => {
		setFields(
			(prevFields) =>
				prevFields.includes(field)
					? prevFields.filter((f) => f !== field) // Remove field
					: [...prevFields, field] // Add field
		);
	};

	// Save user's column preference to local storage
	useEffect(() => {
		localStorage.setItem("selectedColumns", JSON.stringify(fields));
	}, [fields]);

	// Load user's column preference from local storage (if available)
	useEffect(() => {
		const savedColumns = JSON.parse(localStorage.getItem("selectedColumns"));
		if (savedColumns) {
			setFields(savedColumns);
		}
	}, []);

	return (
		<div>
			{/* Column selection UI (only if isActionTab is true) */}

			<div className="mb-4">
				<h3 className="text-xs font-medium text-gray-500 mb-2">
					Visible Columns
				</h3>
				<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1.5">
					{availableFields.map((field) => (
						<div
							key={field.key}
							onClick={() => handleColumnChange(field)}
							onKeyDown={(e) => e.key === "Enter" && handleColumnChange(field)}
							role="button"
							tabIndex={0}
							className={`flex items-center justify-between px-2.5 py-1.5 rounded border transition-colors cursor-pointer text-sm ${
								fields.includes(field)
									? "bg-green-50 border-green-200 text-green-700"
									: "bg-white border-gray-200 hover:bg-green-50 hover:border-green-200 text-gray-600"
							}`}
						>
							<span className="truncate">
								{field.label.replace(/([A-Z])/g, " $1").trim()}
							</span>
							{fields.includes(field) && (
								<svg
									className="w-3.5 h-3.5 ml-1.5 text-green-500 flex-shrink-0"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M5 13l4 4L19 7"
									/>
								</svg>
							)}
						</div>
					))}
				</div>
			</div>

			{/* Table */}
			<div className="overflow-hidden rounded-lg drop-shadow-md border border-gray-100/20 bg-gray-50">
				<table className="min-w-full bg-white">
					<thead className="bg-startgreen_primary-100/20">
						<tr>
							<th className="py-3 px-5 border-b">
								<div className="flex items-center justify-center">
									<div className="relative">
										<input
											type="checkbox"
											onChange={(e) => {
												const { checked } = e.target;
												if (checked) {
													// Sélectionner tous les candidats
													candidates.forEach((candidate) =>
														toggleCandidateSelection(candidate.id)
													);
												} else {
													// Désélectionner tous les candidats
													candidates.forEach((candidate) =>
														toggleCandidateSelection(candidate.id)
													);
												}
											}}
											checked={
												selectedCandidates.length === candidates.length &&
												candidates.length > 0
											}
											className="appearance-none h-6 w-6 border-2 border-gray-300 rounded-md bg-white checked:bg-startgreen_primary-100 checked:border-bg-startgreen_primary-100/30 focus:outline-none focus:ring-2 focus:ring-green-400 transition duration-200 ease-in-out cursor-pointer"
										/>
										<svg
											className="w-4 h-4 text-white absolute top-1 left-1 pointer-events-none"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
											fill="currentColor"
											style={{
												display:
													selectedCandidates.length === candidates.length &&
													candidates.length > 0
														? "block"
														: "none",
											}}
										>
											<path
												fillRule="evenodd"
												d="M16.707 5.293a1 1 0 00-1.414 0L7 13.586 4.707 11.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l9-9a1 1 0 000-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</div>
								</div>
							</th>
							{/* Dynamically render headers based on the fields state */}
							<th className="py-3 px-5 border-b text-left font-medium text-gray-700">
								Nom Complet
							</th>
							<th className="py-3 px-5 border-b text-left font-medium text-gray-700">
								Email
							</th>
							<th className="py-3 px-5 border-b text-left font-medium text-gray-700">
								Région
							</th>
							<th className="py-3 px-5 border-b text-left font-medium text-gray-700">
								Group
							</th>
							{fields.map((field) => (
								<th
									key={field}
									className="py-3 px-5 border-b text-left font-medium text-gray-700"
								>
									{field?.label?.replace(/([A-Z])/g, " $1").trim()}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{candidates.map((candidate, index) => (
							<tr
								key={candidate.id}
								className={
									index % 2 === 0 ? "bg-white" : "bg-startgreen_darkblue-100/10"
								}
							>
								<td className="py-3 px-5 border-b">
									<div className="flex items-center justify-center">
										<div className="relative">
											<input
												type="checkbox"
												checked={selectedCandidates.includes(candidate.id)}
												onChange={() => toggleCandidateSelection(candidate.id)}
												className="appearance-none h-6 w-6 border-2 border-gray-300 rounded-md bg-white checked:bg-startgreen_primary-100 checked:border-bg-startgreen_primary-100/30 focus:outline-none focus:ring-2 focus:ring-green-400 transition duration-200 ease-in-out cursor-pointer"
											/>
											<svg
												className="w-4 h-4 text-white absolute top-1 left-1 pointer-events-none"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												style={{
													display: selectedCandidates.includes(candidate.id)
														? "block"
														: "none",
												}}
											>
												<path
													fillRule="evenodd"
													d="M16.707 5.293a1 1 0 00-1.414 0L7 13.586 4.707 11.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l9-9a1 1 0 000-1.414z"
													clipRule="evenodd"
												/>
											</svg>
										</div>
									</div>
								</td>

								<td className="py-3 px-5 border-b">
									<Link
										target="_blank"
										rel="noopener noreferrer"
										to={`/startgreen-by-iptic/dashboard/participants/${candidate.id}`}
										className="text-blue-600 hover:text-blue-800 font-medium"
									>
										{extractAnswer(candidate, "first_name")}{" "}
										{extractAnswer(candidate, "last_name")}
									</Link>
								</td>
								<td className="py-3 px-5 border-b text-gray-700">
									{extractAnswer(candidate, "email")}
								</td>
								<td className="py-3 px-5 border-b text-gray-700">
									{extractAnswer(candidate, "region")}
								</td>
								<td className="py-3 px-5 border-b text-gray-700">
									{extractAnswer(candidate, "group")}
								</td>

								{/* Dynamically render data cells based on the fields state */}
								{fields.map((field) => (
									<td key={field} className="py-3 px-5 border-b text-gray-700">
										{formatValue(extractAnswer(candidate, field?.key))}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default TableauCandidats;
