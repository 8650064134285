import { useTranslation } from "react-i18next";
import Sidebar from "@pages/(startgreen)/views/(dashboard)/Sidebar";
import Navbar from "@pages/(startgreen)/views/(dashboard)/Navbar";
import React, { useState } from "react";
import { navItems } from "@pages/(startgreen)/views/(dashboard)/Sidebar/navitems.ts";
import useFilter from "@hooks/useFilter/useFilter.ts";
import useSearchParams from "@hooks/useSearchParams.ts";
import {
	declineBulkSubmissions,
	fetchSubmissions,
	groupSub,
	validateBulkSubmissions,
} from "@api/service/startgreen.services.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDebounceFn, useDocumentVisibility, useUpdateEffect } from "ahooks";
import CandidatesTable from "@pages/(startgreen)/views/(visitors)/CandidatesTable.tsx";
import StatusTabs from "@pages/(startgreen)/views/(visitors)/StatusTab.tsx";
import FilterSection from "@pages/(startgreen)/views/(visitors)/FilterSection.tsx";
import useInvalidateOnVisibility from "@hooks/useInvalidateOnVisibility.ts";
import { extractAnswer } from "@pages/(startgreen)/views/(participants)/QuestionListComponent.tsx";
import ExportDataDialog from "@pages/(startgreen)/views/(participants)/ExportDialog.tsx";
import { Toaster } from "react-hot-toast";
import TooltipCard from "@pages/(startgreen)/views/(dashboard)/TooltipCard.tsx";
import { Tooltip } from "@pages/(startgreen)/views/(dashboard)/Tooltip.tsx";

interface GenericParticipantsScreenProps {
	navbarTitle: string;
	submissionType: string;
}

const useSubmissions = (status, type) => {
	return useQuery({
		queryKey: ["submissions", status, type],
		queryFn: () => fetchSubmissions(status, type),
		refetchOnWindowFocus: true,
		refetchInterval: 1000 * 60,
		staleTime: 1000 * 60,
	});
};

export const ParticipantsScreen = ({
	navbarTitle,
	submissionType = "USER",
}) => {
	const documentVisibility = useDocumentVisibility();
	const queryClient = useQueryClient();

	// State to manage the selected tab and type
	const [selectedTab, setSelectedTab] = useState("pending");
	const [selectedType, setSelectedType] = useState("");
	const [selectedCandidates, setSelectedCandidates] = useState([]);
	const [isProcessing, setIsProcessing] = useState(false);

	// Fetch data based on selected tab (status) and type
	const {
		data: candidates,
		isLoading,
		refetch,
		isError,
	} = useSubmissions(selectedTab, submissionType);
	const { t } = useTranslation("coaches-page");
	const [isOpen, setIsOpen] = useState(false);
	const [filters, setFilters] = useSearchParams();
	const [filterState, setFilterState] = useState({
		searchQuery: "",
		selectedGender: [],
		selectedRegion: [],
		selectedProjectFocus: [],
		selectedDevelopmentStage: [],
		selectedSupportNeeds: [],
		selectedSupportThemes: [],
		selectedHackathonParticipation: [],
		selectedGroup: [],
		selectedPresenceConfirmed: [],
		selectedPreviousSupport: [],
		selectedDiscoverySource: [],
		selectedMinAge: 18,
		selectedMaxAge: 100,
		selectedMinEmployeesCount: 0,
		selectedMaxEmployeesCount: 100,
		selectedMinSalesRevenue: 0,
		selectedMaxSalesRevenue: 10_000,
		selectedProfessionalStatus: [],
		selectedEducationLevel: [],
		selectedMinDate: "",
		selectedMaxDate: "",
	});
	// Extended function to check if a specific filter or any filter is applied
	const isFilterApplied = (filterKey = null) => {
		if (filterKey) {
			// Specific filter check
			switch (filterKey) {
				case "searchQuery":
					return filterState.searchQuery.trim() !== "";
				case "selectedGender":
				case "selectedRegion":
				case "selectedDevelopmentStage":
				case "selectedProjectFocus":
				case "selectedSupportNeeds":
				case "selectedSupportThemes":
				case "selectedPreviousSupport":
				case "selectedGroup":
				case "selectedPresenceConfirmed":
				case "selectedHackathonParticipation":
				case "selectedDiscoverySource":
				case "selectedProfessionalStatus":
				case "selectedEducationLevel":
					return filterState[filterKey].length > 0;
				case "selectedMinAge":
					return filterState.selectedMinAge !== 18;
				case "selectedMaxAge":
					return filterState.selectedMaxAge !== 100;
				case "selectedMinEmployeesCount":
					return filterState.selectedMinEmployeesCount !== 0;
				case "selectedMaxEmployeesCount":
					return filterState.selectedMaxEmployeesCount !== 100;
				case "selectedMinSalesRevenue":
					return filterState.selectedMinSalesRevenue !== 0;
				case "selectedMaxSalesRevenue":
					return filterState.selectedMaxSalesRevenue !== 10_000;
				case "selectedMinDate":
					return filterState.selectedMinAge !== "";
				case "selectedMaxDate":
					return filterState.selectedMaxDate !== "";
				default:
					return false;
			}
		} else {
			// General check for any filter being applied
			return (
				filterState.searchQuery.trim() !== "" ||
				filterState.selectedGender.length > 0 ||
				filterState.selectedRegion.length > 0 ||
				filterState.selectedProjectFocus.length > 0 ||
				filterState.selectedDevelopmentStage.length > 0 ||
				filterState.selectedSupportNeeds.length > 0 ||
				filterState.selectedSupportThemes.length > 0 ||
				filterState.selectedPreviousSupport.length > 0 ||
				filterState.selectedGroup.length > 0 ||
				filterState.selectedPresenceConfirmed.length > 0 ||
				filterState.selectedHackathonParticipation.length > 0 ||
				filterState.selectedDiscoverySource.length > 0 ||
				filterState.selectedProfessionalStatus.length > 0 ||
				filterState.selectedEducationLevel.length > 0 ||
				filterState.selectedMinAge !== 18 ||
				filterState.selectedMaxAge !== 100 ||
				filterState.selectedMinEmployeesCount !== 0 ||
				filterState.selectedMaxEmployeesCount !== 100 ||
				filterState.selectedMinSalesRevenue !== 0 ||
				filterState.selectedMaxSalesRevenue !== 10_000 ||
				filterState.selectedMinDate !== "" ||
				filterState.selectedMaxDate !== ""
			);
		}
	};

	// Function that utilizes isFilterApplied to check if any filter is applied
	const areAnyFiltersApplied = () => {
		return isFilterApplied();
	};

	const { run: debouncedInvalidateQueries } = useDebounceFn(
		() => {
			queryClient.invalidateQueries(["submissions", selectedTab, selectedType]);
		},
		{ wait: 1000 }
	);

	useUpdateEffect(() => {
		if (documentVisibility === "visible") {
			debouncedInvalidateQueries();
		}
	}, [documentVisibility]);

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	const groupSubMutation = useMutation({
		mutationFn: groupSub,
		onMutate: () => {
			// setIsDeclining(true);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries([
				"submissions",
				selectedTab,
				selectedType,
			]);
			// setIsDeclining(false);
			// await queryClient.invalidateQueries([
			// 	"submissions",
			// 	selectedTab,
			// 	selectedType,
			// ]);
			setSelectedCandidates([]);
		},
		onError: (error) => {
			// setIsDeclining(false);
			console.error("Decline error:", error);
			// Handle error as needed (e.g., show an error notification)
		},
	});

	const validateBulkMutation = useMutation({
		mutationFn: validateBulkSubmissions,
		onMutate: () => {
			setIsValidating(true);
		},
		onSuccess: async () => {
			setIsValidating(false);
			await queryClient.invalidateQueries([
				"submissions",
				selectedTab,
				selectedType,
			]);
			setSelectedCandidates([]);
		},
		onError: () => {
			setIsValidating(false);
		},
	});

	const declineBulkMutation = useMutation({
		mutationFn: declineBulkSubmissions,
		onMutate: () => {
			setIsDeclining(true);
		},
		onSuccess: async () => {
			setIsDeclining(false);
			await queryClient.invalidateQueries([
				"submissions",
				selectedTab,
				selectedType,
			]);
			setSelectedCandidates([]);
		},
		onError: (error) => {
			setIsDeclining(false);
			console.error("Decline error:", error);
			// Handle error as needed (e.g., show an error notification)
		},
	});

	const handleFilterChange = (key, value) => {
		setFilterState((prevState) => {
			let newState;
			if (key === "selectedMinDate" || key === "selectedMaxDate") {
				newState = {
					...prevState,
					[key]: value,
				};
			}
			// Handle numeric filters (e.g., Min/Max Age)
			else if (
				key === "selectedMinAge" ||
				key === "selectedMaxAge" ||
				key === "selectedMinEmployeesCount" ||
				key === "selectedMaxEmployeesCount" ||
				key === "selectedMinSalesRevenue" ||
				key === "selectedMaxSalesRevenue"
			) {
				newState = {
					...prevState,
					[key]: value,
				};
			}
			// Handle the search query filter
			else if (key === "searchQuery") {
				const searchQueries = String(value)
					.split(";")
					// .map((query) => query.trim())
					.filter((query) => query !== "");
				newState = {
					...prevState,
					searchQuery: searchQueries?.length ? searchQueries : [],
				};
			}
			// Handle all other filters that are arrays
			else {
				newState = {
					...prevState,
					[key]: prevState[key].includes(value)
						? prevState[key].filter((option) => option !== value) // Uncheck
						: [...prevState[key], value], // Check
				};
			}

			// Update the filters in the search params
			const newFilters = {
				search: newState.searchQuery,
				gender: newState.selectedGender.join(","),
				region: newState.selectedRegion.join(","),
				projectFocus: newState.selectedProjectFocus.join(","),
				developmentStage: newState.selectedDevelopmentStage.join(","),
				supportNeeds: newState.selectedSupportNeeds.join(","),
				supportThemes: newState.selectedSupportThemes.join(","),
				previousSupport: newState.selectedPreviousSupport.join(","),
				group: newState.selectedGroup.join(","),
				presenceConfirmed: newState.selectedPresenceConfirmed.join(","),
				hackathonParticipation:
					newState.selectedHackathonParticipation.join(","),
				discoverySource: newState.selectedDiscoverySource.join(","),
				minAge: newState.selectedMinAge.toString(),
				maxAge: newState.selectedMaxAge.toString(),
				minEmployeesCount: newState.selectedMinEmployeesCount.toString(),
				maxEmployeesCount: newState.selectedMaxEmployeesCount.toString(),
				minSalesRevenue: newState.selectedMinSalesRevenue.toString(),
				maxSalesRevenue: newState.selectedMaxSalesRevenue.toString(),
				minDate: newState.selectedMinDate,
				maxDate: newState.selectedMaxDate,
				professionalStatus: newState.selectedProfessionalStatus.join(","),
				educationLevel: newState.selectedEducationLevel.join(","),
			};

			// setFilters(newFilters);
			return newState;
		});
	};

	const initialSearchProps = {
		query: filterState.searchQuery,
		fields: [
			"email",
			"group",
			"full_name",
			"answers.question.region",
			// "full_name", // Added full_name field
			// "answers.question.first_name",
			// "answers.question.last_name",
			"answers.question.project_activity",
			"answers.question.development_stage",
			"answers.question.support_needs",
			"answers.question.professional_situation",
			"answers.question.education_level",
		],
	};

	const initialFiltersProps = {
		"answers.question.region": {
			values: filterState.selectedRegion,
		},
		"answers.question.gender": {
			values: filterState.selectedGender,
		},
		"answers.question.project_activity": {
			values: filterState.selectedProjectFocus,
		},
		"answers.question.hackathon_participation": {
			values: filterState.selectedHackathonParticipation,
		},
		group: {
			values: filterState.selectedGroup,
		},
		isConfirmed: {
			values: filterState.selectedPresenceConfirmed,
		},
		"answers.question.previous_support": {
			values: filterState.selectedPreviousSupport,
		},
		"answers.question.development_stage": {
			values: filterState.selectedDevelopmentStage,
		},
		"answers.question.support_needs": {
			values: filterState.selectedSupportNeeds,
		},
		"answers.question.support_themes": {
			values: filterState.selectedSupportThemes,
		},

		"answers.question.discovery_source": {
			values: filterState.selectedDiscoverySource,
		},
		"answers.question.age": {
			min: filterState.selectedMinAge,
			max: filterState.selectedMaxAge,
		},
		"answers.question.employees_number": {
			min: filterState.selectedMinEmployeesCount,
			max: filterState.selectedMaxEmployeesCount,
		},
		"answers.question.sales_revenue": {
			min: filterState.selectedMinSalesRevenue,
			max: filterState.selectedMaxSalesRevenue,
		},
		"answers.question.professional_situation": {
			values: filterState.selectedProfessionalStatus,
		},
		"answers.question.education_level": {
			values: filterState.selectedEducationLevel,
		},
		createdAt: {
			min: filterState.selectedMinDate
				? new Date(filterState.selectedMinDate)
				: null,
			max: filterState.selectedMaxDate
				? new Date(filterState.selectedMaxDate)
				: null,
		},
		// "answers.question.region": {
		// 	values: filterState.selectedRegion,
		// },
		// development_stage: { values: filterState.selectedDevelopmentStage },
	};
	const [itemsPerPage, setItemsPerPage] = useState(50);
	const [currentPageIndex, setCurrentPageIndex] = useState(1);
	const [selectedGroup, setSelectedGroup] = React.useState("");

	const options = {
		itemsPerPage,
		strict: true,
		caseSensitive: false,
		paginate: true,
	};
	const {
		filteredData,
		filteredDataCount,
		totalDataLines,
		currentPage,
		totalPages,
		setPage,
		loadMoreData,
	} = useFilter(
		candidates || [],
		initialSearchProps,
		initialFiltersProps,
		options
	);
	const validateMutation = () => {};
	const handleBatchAction = (status: string) => {
		setIsProcessing(true);
		if (status === "accepted") {
			validateBulkMutation.mutate(selectedCandidates);
		} else if (status === "declined") {
			declineBulkMutation.mutate(selectedCandidates);
		}
	};

	const handleGroupSubs = (status: string) => {
		setIsProcessing(true);
		groupSubMutation.mutate({ selectedCandidates, selectedGroup });
	};
	const toggleCandidateSelection = (candidateId) => {
		setSelectedCandidates((prevSelected) => {
			if (prevSelected.includes(candidateId)) {
				return prevSelected.filter((id) => id !== candidateId);
			} else {
				return [...prevSelected, candidateId];
			}
		});
	};
	const resetFilters = () => {
		// Default filter values
		const defaultFilterState = {
			searchQuery: "",
			selectedGender: [],
			selectedRegion: [],
			selectedProjectFocus: [],
			selectedDevelopmentStage: [],
			selectedSupportNeeds: [],
			selectedSupportThemes: [],
			selectedHackathonParticipation: [],
			selectedGroup: [],
			selectedPresenceConfirmed: [],
			selectedPreviousSupport: [],
			selectedDiscoverySource: [],
			selectedMinAge: 18,
			selectedMaxAge: 100,
			selectedMinEmployeesCount: 0,
			selectedMaxEmployeesCount: 100,
			selectedMinSalesRevenue: 0,
			selectedMaxSalesRevenue: 10_000,
			selectedProfessionalStatus: [],
			selectedEducationLevel: [],
			selectedMinDate: "",
			selectedMaxDate: "",
		};

		setFilterState(defaultFilterState);

		// Update URL search parameters to reflect reset
		setFilters({
			search: "",
			gender: "",
			region: "",
			projectFocus: "",
			developmentStage: "",
			supportNeeds: "",
			supportThemes: "",
			hackathonParticipation: "",
			group: "",
			presenceConfirmed: "",
			previousSupport: "",
			discoverySource: "",
			minAge: "18",
			maxAge: "100",
			minEmployeesCount: "0",
			maxEmployeesCount: "100",
			minSalesRevenue: "0",
			maxSalesRevenue: "10000",
			professionalStatus: "",
			educationLevel: "",
			minDate: "",
			maxDate: "",
		});
	};
	const [isValidating, setIsValidating] = useState(false);
	const [isDeclining, setIsDeclining] = useState(false);
	const isActionTab = selectedTab !== "accepted" && selectedTab !== "declined";
	const queryKeys = ["submissions", selectedTab, selectedType];
	const { refetchQueries } = useInvalidateOnVisibility(queryKeys);

	const frNavbarTitle = "Titre de la barre de navigation";
	const frListText = `Liste des ${submissionType === "USER" ? "candidats" : "visiteurs"}`;
	const frLoadMoreText = "Charger plus";
	const frErrorText = "Erreur de chargement des données";
	const frLoadingText = "Chargement...";
	const frAcceptSelectedText = "Accepter sélectionné";
	const frDeclineSelectedText = "Refuser sélectionné";
	const frPageText = (currentPage, totalPages) =>
		`Page ${currentPage} sur ${totalPages}`;
	const frPreviousText = "Précédent";
	const frNextText = "Suivant";
	const [isExportDialogOpen, setExportDialogOpen] = useState(false);

	const tooltipPoints = [
		"Cliquer sur le nom d'un candidat pour voir toutes ses informations.",
		"Filtrer par date de signature de la candidature et utiliser les dates de phase comme modèle pour vous aider.",
		"Exporter les données au format CSV ou Excel en cliquant sur 'Exporter les données'.",
	];
	return (
		<div className="flex bg-red-[#F3F3F3]">
			<Sidebar
				isOpen={isOpen}
				toggleSidebar={toggleSidebar}
				navItems={navItems}
			/>
			<div className="flex-grow">
				<Navbar toggleSidebar={toggleSidebar} title={navbarTitle} />
				{/*--------------------------------------*/}
				<main className="p-5 space-y-6 flex">
					<Toaster position="bottom-center" reverseOrder={false} />
					<div className="flex-grow mr-4">
						<TooltipCard
							title="Détails sur la gestion des candidats"
							description="Cette page contient la liste des candidats, qu'ils soient en attente d'une action, acceptés ou refusés. Vous pouvez utiliser les options sur la droite pour affiner votre recherche et trouver le bon profil Dans les options, vous pouvez :"
							points={tooltipPoints}
						/>
						{/* Tabs Section */}
						<div>
							<Tooltip
								title="Filtrer par status des candidats"
								description="Sélectionnez le statut pour voir la liste des candidats (candidats acceptés, refusés, etc.)"
							/>
							<StatusTabs
								selectedTab={selectedTab}
								setSelectedTab={setSelectedTab}
							/>
						</div>

						<div className={"flex flex-row justify-between items-center mb-4"}>
							<h2 className="text-xl font-semibold">{frListText}</h2>
							<div className={"flex gap-2"}>
								<button
									onClick={() => setExportDialogOpen(true)}
									className="bg-startgreen_tertiary-100 hover:bg-opacity-75 text-white font-bold py-2 px-4 rounded"
								>
									Exporter les données
								</button>
								<ExportDataDialog
									isOpen={isExportDialogOpen}
									onClose={() => setExportDialogOpen(false)}
								/>
								<button
									onClick={() => refetchQueries()}
									className="flex flex-row gap-2 items-center bg-startgreen_darkblue-100 hover:bg-startgreen_darkblue-100/80 text-white font-bold py-2 px-4 rounded"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1.5}
										stroke="currentColor"
										className="size-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
										/>
									</svg>
									Actualiser
								</button>
							</div>
						</div>
						{isActionTab && selectedCandidates.length > 0 && (
							<div className="mb-4">
								<button
									onClick={() => handleBatchAction("accepted")}
									disabled={isValidating || isDeclining}
									className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mr-2 disabled:bg-black-500"
								>
									{isValidating ? "Acceptant..." : frAcceptSelectedText}
								</button>
								<button
									onClick={() => handleBatchAction("declined")}
									disabled={isDeclining || isValidating}
									className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded disabled:bg-black-500"
								>
									{isDeclining ? "Refusant..." : frDeclineSelectedText}
								</button>
							</div>
						)}

						{selectedCandidates.length > 0 && (
							<div className="mb-4">
								<div className="mt-4">
									<select
										className="border border-gray-300 rounded py-2 px-4 mr-2"
										onChange={(e) => setSelectedGroup(e.target.value)}
									>
										<option value="C0">Select Group</option>
										<option value="C0">C0</option>
										<option value="C1">C1</option>
										<option value="C2">C2</option>
										<option value="C3">C3</option>
										<option value="C4">C4</option>
										<option value="C5">C5</option>
									</select>
									<button
										onClick={() => handleGroupSubs(selectedGroup)}
										// disabled={!selectedGroup}
										className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:bg-black-500"
									>
										Confirm
									</button>
								</div>
							</div>
						)}

						{/* Sélecteur pour choisir le nombre d'éléments par page */}
						<div className="flex justify-between w-full items-center mb-4">
							<span>
								Page {currentPage} de {totalPages}
							</span>
							<div className={"flex justify-end items-end flex-col gap-2"}>
								<h3
									className={
										"font-bold bg-startgreen_primary-100/20 p-2 rounded"
									}
								>
									Nombre de candidats : {totalDataLines}
								</h3>
								<div>
									<label htmlFor="itemsPerPage" className="mr-2">
										Éléments par page :
									</label>
									<select
										id="itemsPerPage"
										value={itemsPerPage}
										onChange={(e) => {
											setItemsPerPage(Number(e.target.value));
											setPage(1); // Réinitialiser à la première page lorsque le nombre d'éléments par page change
										}}
										className="border border-gray-300 rounded px-2 py-1"
									>
										<option value={5}>5</option>
										<option value={10}>10</option>
										<option value={25}>25</option>
										<option value={50}>50</option>
										<option value={100}>100</option>
										<option value={150}>150</option>
										<option value={200}>200</option>
									</select>
								</div>
							</div>
						</div>

						{isLoading ? (
							<p>{frLoadingText}</p>
						) : isError ? (
							<p>{frErrorText}</p>
						) : (
							<CandidatesTable
								candidates={filteredData}
								extractAnswer={extractAnswer}
								toggleCandidateSelection={toggleCandidateSelection}
								selectedCandidates={selectedCandidates}
								isActionTab={isActionTab}
								initialFields={
									[
										// { key: "isConfirmed", label: "Confirmé" },
										// { key: "hackathon_participation", label: "Hackathon" },
										// {
										// 	key: "professional_situation",
										// 	label: "Situation Professionnelle",
										// },
										// { key: "education_level", label: "Niveau d'education" },
										// "isConfirmed",
										// "education_level",
										// "professional_situation",
									]
								} // Initial set of fields to display
							/>
						)}

						<div className="flex flex-col justify-center items-center mt-4 space-x-2">
							<div className="flex justify-between w-full items-center">
								<button
									onClick={() => setPage(currentPage - 1)}
									disabled={currentPage === 1}
									className="bg-startgreen_primary-100/60 hover:bg-startgreen_primary-100/40 cursor-pointer text-white font-bold py-2 px-4 rounded"
								>
									Précédent
								</button>

								<div className="flex gap-2">
									{/* Logic for Dynamic Pagination Buttons */}
									{currentPage > 2 && (
										<button
											onClick={() => setPage(1)}
											className={`bg-startgreen_darkblue-100 text-white hover:bg-opacity-80 font-bold py-2 px-4 rounded`}
										>
											1
										</button>
									)}

									{currentPage > 3 && (
										<span className="text-gray-500 font-bold">...</span>
									)}

									{/* Previous Page (if applicable) */}
									{currentPage > 1 && (
										<button
											onClick={() => setPage(currentPage - 1)}
											className={`bg-startgreen_darkblue-100 text-white hover:bg-opacity-80 font-bold py-2 px-4 rounded`}
										>
											{currentPage - 1}
										</button>
									)}

									{/* Current Page */}
									<button
										className={`bg-startgreen_darkblue-100/20 text-white font-bold py-2 px-4 rounded`}
									>
										{currentPage}
									</button>

									{/* Next Page (if applicable) */}
									{currentPage < totalPages && (
										<button
											onClick={() => setPage(currentPage + 1)}
											className={`bg-startgreen_darkblue-100 text-white hover:bg-opacity-80 font-bold py-2 px-4 rounded`}
										>
											{currentPage + 1}
										</button>
									)}

									{currentPage < totalPages - 2 && (
										<span className="text-gray-500 font-bold">...</span>
									)}

									{currentPage < totalPages - 1 && (
										<button
											onClick={() => setPage(totalPages)}
											className={`bg-startgreen_darkblue-100 text-white hover:bg-opacity-80 font-bold py-2 px-4 rounded`}
										>
											{totalPages}
										</button>
									)}
								</div>

								<button
									onClick={() => setPage(currentPage + 1)}
									disabled={currentPage === totalPages}
									className="bg-startgreen_primary-100 hover:bg-opacity-80 cursor-pointer text-white font-bold py-2 px-4 rounded"
								>
									Suivant
								</button>
							</div>
						</div>
					</div>

					{/* ------------------------------------ */}
					<FilterSection
						resetFilters={resetFilters}
						filterState={filterState}
						handleFilterChange={handleFilterChange}
					/>
					{/* ------------------------------------ */}
				</main>
			</div>
		</div>
	);
};
export default ParticipantsScreen;
