import QrScanner from "qr-scanner";
import { useRef, useEffect } from "react";

interface QrScannerCamProps {
	onScan: (data: string | null) => void;
	onError: (error: any) => void;
}

const QrScannerCam: React.FC<QrScannerCamProps> = ({ onScan, onError }) => {
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		const video: HTMLVideoElement | null = videoRef.current;
		if (!video) return;

		const qrScanner = new QrScanner(
			video,
			(result) => {
				onScan(result.data);
			},
			{
				returnDetailedScanResult: true,
				highlightScanRegion: true,
				highlightCodeOutline: true,
			}
		);

		void qrScanner.start();

		return () => {
			qrScanner.stop();
			qrScanner.destroy();
		};
	}, [onScan, onError]);

	return (
		<div className="relative w-full overflow-hidden shadow-lg qr-scanner-container aspect-square rounded-2xl">
			<video
				ref={videoRef}
				className="absolute inset-0 object-cover w-full h-full rounded-2xl"
				playsInline
				autoPlay
				muted
			/>
			{/* Scanner overlay */}
			<div className="qr-scanner-overlay">
				<div className="qr-scan-line"></div>
				<div className="absolute w-10 h-10 border-t-2 border-l-2 border-green-500 rounded-tl-lg top-3 left-3"></div>
				<div className="absolute w-10 h-10 border-t-2 border-r-2 border-green-500 rounded-tr-lg top-3 right-3"></div>
				<div className="absolute w-10 h-10 border-b-2 border-l-2 border-green-500 rounded-bl-lg bottom-3 left-3"></div>
				<div className="absolute w-10 h-10 border-b-2 border-r-2 border-green-500 rounded-br-lg bottom-3 right-3"></div>
			</div>
			{/* Scanner hint text */}
			<div className="absolute left-0 right-0 text-center bottom-5">
				<p className="inline-block px-4 py-2 text-sm font-medium text-white rounded-full bg-black/30 backdrop-blur-sm">
					Align QR code in the frame
				</p>
			</div>
		</div>
	);
};

export default QrScannerCam;
