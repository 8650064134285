import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appConfig } from "@config/appConfig.ts";
import axios from "axios";
import { useQuery } from "react-query";
import type { EmailEntry } from "../hackathon-components/EmailImporter";

// This function will perform the POST request for validation
export const validateCandidate = async (id) => {
	const response = await fetch(
		`${appConfig.startGreenUrl}/hackathon/validate/${id}`,
		{
			method: "POST",
		}
	);

	if (!response.ok) {
		throw new Error("Failed to validate candidate");
	}

	return response.json();
};

export const validateCandidatePresence = async (id: string) => {
	return axios.post(`${appConfig.startGreenUrl}/hackathon/validate/${id}`);
};

export const invalidateCandidatePresence = async (id: string) => {
	return axios.post(`${appConfig.startGreenUrl}/hackathon/invalidate/${id}`);
};

export const addCandidates = async ({ emails }: { emails: EmailEntry[] }) => {
	console.log("FRONT emails", emails);
	// Create an array of CreateVisitorDto objects
	const visitorsDto = emails.map((email) => ({
		email: email.email,
		firstName: email.firstName,
		lastName: email.lastName,
		isConfirmed: false, // Default value if needed
		phase: undefined, // Optional field
		userType: email.userType,
		invitedBy: undefined, // Optional field
	}));

	return axios.post(
		`${appConfig.startGreenUrl}/hackathon/candidates`,
		visitorsDto
	);
};

//------------------------------------//
export const fetchRooms = async () => {
	const response = await fetch(`${appConfig.startGreenUrl}/hackathon/rooms`);
	if (!response.ok) {
		throw new Error("Network response was not ok");
	}
	return response.json();
};
