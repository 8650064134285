import React, { useState, useEffect } from "react";
import {
	ChevronDown,
	ChevronUp,
	MoreVertical,
	UserCheck,
	Check,
	Users,
	User,
	UserX,
	Loader2,
	Layout,
	List,
	Clipboard,
	Award,
	Search,
	ArrowUpDown,
	AlertTriangle,
	X,
	Filter,
	Settings,
	Send,
	Circle,
	Eye,
	EyeOff,
	MapPin,
	RefreshCw,
} from "lucide-react";
import { useIsMobile } from "@pages/(startgreen)/views/(hackathon)/hooks/use-mobile.tsx";
import { useFilter } from "@pages/(startgreen)/views/(hackathon)/hooks/useFilter.ts";
import toast from "react-hot-toast";

interface Candidate {
	id: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	isConfirmed?: boolean;
	userType?: "CANDIDATE" | "VISITOR" | "JUDGE" | string;
	room?: { id: string; name: string };
	badgeBase64?: string;
}

interface Room {
	id: string;
	name: string;
}

interface CandidatesTableProps {
	data?: Candidate[];
	isLoading?: boolean;
	visibleColumns?: {
		email?: boolean;
		name?: boolean;
		lastName?: boolean;
		present?: boolean;
	};
	validateCandidateMutation: (id: string) => void;
	invalidateCandidateMutation: (id: string) => void;
	rooms?: Room[];
	isLoadingRooms?: boolean;
	changeRoomMutation: {
		mutateAsync: (params: {
			candidateId: string;
			roomId: string;
		}) => Promise<any>;
		isLoading?: boolean;
	};
	sendBadgeMutation: {
		mutateAsync: (params: { candidateIds: string[] }) => Promise<any>;
		isLoading?: boolean;
	};
	onRefresh?: () => void; // Add this prop for refresh functionality
}

const CandidatesTable: React.FC<CandidatesTableProps> = ({
	data = [],
	isLoading = false,
	visibleColumns: initialVisibleColumns = {
		room: true,
		email: true,
		name: true,
		lastName: true,
		present: true,
	},
	validateCandidateMutation,
	invalidateCandidateMutation,
	rooms = [],
	isLoadingRooms = false,
	changeRoomMutation,
	sendBadgeMutation,
	onRefresh,
}) => {
	const [expandedRooms, setExpandedRooms] = useState<Record<string, boolean>>(
		{}
	);
	const [expandedUsers, setExpandedUsers] = useState<Record<string, boolean>>(
		{}
	);
	const [selectedCandidates, setSelectedCandidates] = useState<string[]>([]);
	const [selectedRoomId, setSelectedRoomId] = useState<string>("");
	const [viewMode, setViewMode] = useState<"accordion" | "list">("accordion");
	const [isChangingRoom, setIsChangingRoom] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState<{
		show: boolean;
		candidateId: string;
		action: "present" | "absent";
	} | null>(null);
	const [isSendingBadge, setIsSendingBadge] = useState(false);
	const [visibleColumns, setVisibleColumns] = useState(initialVisibleColumns);
	const [showColumnSettings, setShowColumnSettings] = useState(false);
	const isMobile = useIsMobile();
	const [isRefreshing, setIsRefreshing] = useState(false);

	const { filters, setFilters, filteredData } = useFilter(data);

	// Auto-expand the first room for better UX
	useEffect(() => {
		if (rooms.length > 0 && Object.keys(expandedRooms).length === 0) {
			setExpandedRooms({ [rooms[0].name]: true });
		}
	}, [rooms]);

	if (isLoading || isLoadingRooms) {
		return (
			<div className="flex flex-col items-center justify-center h-64 bg-white border rounded-lg shadow-sm border-slate-200">
				<Loader2 className="w-8 h-8 mb-2 text-primary animate-spin" />
				<p className="text-slate-600 animate-pulse">Loading...</p>
			</div>
		);
	}

	if (data.length === 0 || !rooms?.length) {
		return (
			<div className="flex flex-col items-center justify-center h-64 bg-white border rounded-lg shadow-sm border-slate-200">
				<UserX className="w-10 h-10 mb-3 text-slate-400" />
				<p className="font-medium text-slate-500">
					No candidates or rooms found.
				</p>
			</div>
		);
	}

	// Calculate a colSpan for the expanded detail row
	const colSpan =
		1 +
		(viewMode === "list" ? 1 : 0) +
		(visibleColumns?.room ? 1 : 0) +
		(visibleColumns?.email ? 1 : 0) +
		(visibleColumns?.name ? 1 : 0) +
		(visibleColumns?.lastName ? 1 : 0) +
		(visibleColumns?.present ? 1 : 0) +
		1;

	// Map room IDs to names
	const roomMap = rooms.reduce(
		(acc, room) => {
			acc[room.id] = room.name;
			return acc;
		},
		{} as Record<string, string>
	);

	const sortedRooms = [...rooms].sort((a, b) => a.name.localeCompare(b.name));

	// Group filtered users by room name, with unassigned users grouped under "Unassigned"
	const groupedCandidates = filteredData.reduce(
		(acc, user) => {
			const roomName = user?.room?.id ? roomMap[user.room.id] : "Unassigned";
			if (!acc[roomName]) {
				acc[roomName] = [];
			}
			acc[roomName].push(user);
			return acc;
		},
		{} as Record<string, Candidate[]>
	);

	// Get counts of candidates and visitors per room
	const getRoomCounts = (roomName: string) => {
		const candidates = groupedCandidates[roomName] || [];
		const participantCount = candidates.filter(
			(c) => c.userType === "CANDIDATE"
		).length;
		const visitorCount = candidates.filter(
			(c) => c.userType === "VISITOR"
		).length;
		const judgeCount = candidates.filter(
			(c) => c.userType === "JUDGE"
		).length;
		return { participantCount, visitorCount, judgeCount, total: candidates.length };
	};

	// Toggle the expanded state for a given user
	const toggleUser = (userId: string) => {
		setExpandedUsers((prev) => ({
			...prev,
			[userId]: !prev[userId],
		}));
	};

	// Handle candidate presence status change with confirmation
	const handleTogglePresence = (
		id: string,
		currentStatus: boolean | undefined
	) => {
		if (showConfirmDialog) {
			// Confirmation dialog is shown, proceed with action
			if (showConfirmDialog.action === "present") {
				validateCandidateMutation(id);
				// toast({
				// 	title: "Status Updated",
				// 	description: "The candidate has been marked as present.",
				// 	variant: "default",
				// });
			} else {
				invalidateCandidateMutation(id);
				// Logic to mark as absent would go here
				// This would need to be implemented on the backend
				// toast({
				// 	title: "Status Updated",
				// 	description: "The candidate has been marked as absent.",
				// 	variant: "default",
				// });
			}
			setShowConfirmDialog(null);
		} else {
			// Show confirmation dialog
			setShowConfirmDialog({
				show: true,
				candidateId: id,
				action: currentStatus ? "absent" : "present",
			});
		}
	};

	// Toggle room accordion open/close
	const toggleRoom = (room: string) => {
		setExpandedRooms((prev) => ({
			...prev,
			[room]: !prev[room],
		}));
	};

	// Change room for selected candidates
	const handleChangeRoom = async () => {
		if (selectedCandidates.length === 0 || !selectedRoomId) return;

		setIsChangingRoom(true);
		try {
			for (const candidateId of selectedCandidates) {
				await changeRoomMutation.mutateAsync({
					candidateId,
					roomId: selectedRoomId,
				});
			}
			toast.success(
				`${selectedCandidates.length} candidates moved to ${
					selectedRoomId === "0"
						? "Unassigned"
						: roomMap[selectedRoomId] || "new room"
				}.`,
				{
					className: "bg-green-500 text-white",
				}
			);

			setSelectedCandidates([]);
			setSelectedRoomId("");
		} catch (error) {
			toast.error(`Failed to change room. Please try again. ${error.message}`, {
				className: "bg-green-500 text-white",
			});
			console.error("Error changing room:", error);
		} finally {
			setIsChangingRoom(false);
		}
	};

	// Handle sending badge to selected candidates
	const handleSendBadge = async () => {
		if (selectedCandidates.length === 0) return;

		setIsSendingBadge(true);

		try {
			await sendBadgeMutation.mutateAsync({
				candidateIds: selectedCandidates,
			});

			toast.success(
				`Badges have been sent to ${selectedCandidates.length} candidates.`,
				{
					className: "bg-green-500 text-white",
				}
			);

			setSelectedCandidates([]);
		} catch (error) {
			toast.error(`Failed to send badges. Please try again. ${error.message}`, {
				className: "bg-red-500 text-white",
			});
			console.error("Error sending badges:", error);
		} finally {
			setIsSendingBadge(false);
		}
	};

	// Handle selection of a candidate (checkbox)
	const handleCandidateSelect = (candidateId: string, checked: boolean) => {
		if (checked) {
			setSelectedCandidates((prev) => [...prev, candidateId]);
		} else {
			setSelectedCandidates((prev) => prev.filter((id) => id !== candidateId));
		}
	};

	// Select all candidates in a specific room
	const handleSelectAllInRoom = (roomName: string, checked: boolean) => {
		const candidatesInRoom = groupedCandidates[roomName] || [];

		if (checked) {
			const candidateIds = candidatesInRoom.map((c) => c.id);
			setSelectedCandidates((prev) => {
				const newSelection = [...prev];
				candidateIds.forEach((id) => {
					if (!newSelection.includes(id)) {
						newSelection.push(id);
					}
				});
				return newSelection;
			});
		} else {
			setSelectedCandidates((prev) =>
				prev.filter((id) => !candidatesInRoom.some((c) => c.id === id))
			);
		}
	};

	// Toggle column visibility
	const toggleColumnVisibility = (column: keyof typeof visibleColumns) => {
		setVisibleColumns((prev) => ({
			...prev,
			[column]: !prev[column],
		}));
	};

	// Get row class based on user type
	const getUserRowClass = (user: Candidate) => {
		if (user?.userType === "CANDIDATE") {
			return "bg-green-50 hover:bg-green-100";
		} else if (user?.userType === "VISITOR") {
			return "bg-startgreen_tertiary-100/10 hover:bg-amber-100";
		} else if (user?.userType === "JUDGE") {
			return "bg-startgreen_tertiary-100/20 hover:bg-amber-100";
		}
		return "hover:bg-slate-100";
	};

	// Render the column settings dropdown
	const renderColumnSettings = () => (
		<div
			className={`absolute right-0 top-full mt-2 w-56 bg-white rounded-md shadow-lg z-50 border border-green-100 ${
				showColumnSettings ? "" : "hidden"
			}`}
		>
			<div className="py-1 border-b border-green-100">
				<div className="px-4 py-2 text-sm font-medium text-green-700">
					Column Visibility
				</div>
			</div>
			<div className="py-1">
				<label className="flex items-center px-4 py-2 text-sm cursor-pointer hover:bg-green-50">
					<input
						type="checkbox"
						checked={!!visibleColumns.email}
						onChange={() => {
							toggleColumnVisibility("email");
						}}
						className="mr-2 text-green-600 border-green-300 rounded focus:ring-green-500/25"
					/>
					Email
				</label>
				<label className="flex items-center px-4 py-2 text-sm cursor-pointer hover:bg-green-50">
					<input
						type="checkbox"
						checked={!!visibleColumns.name}
						onChange={() => {
							toggleColumnVisibility("name");
						}}
						className="mr-2 text-green-600 border-green-300 rounded focus:ring-green-500/25"
					/>
					First Name
				</label>
				<label className="flex items-center px-4 py-2 text-sm cursor-pointer hover:bg-green-50">
					<input
						type="checkbox"
						checked={!!visibleColumns.lastName}
						onChange={() => {
							toggleColumnVisibility("lastName");
						}}
						className="mr-2 text-green-600 border-green-300 rounded focus:ring-green-500/25"
					/>
					Last Name
				</label>
				<label className="flex items-center px-4 py-2 text-sm cursor-pointer hover:bg-green-50">
					<input
						type="checkbox"
						checked={!!visibleColumns.present}
						onChange={() => {
							toggleColumnVisibility("present");
						}}
						className="mr-2 text-green-600 border-green-300 rounded focus:ring-green-500/25"
					/>
					Status
				</label>
				<label className="flex items-center px-4 py-2 text-sm cursor-pointer hover:bg-green-50">
					<input
						type="checkbox"
						checked={!!visibleColumns.room}
						onChange={() => {
							toggleColumnVisibility("room");
						}}
						className="mr-2 text-green-600 border-green-300 rounded focus:ring-green-500/25"
					/>
					Room
				</label>
			</div>
		</div>
	);

	// Handle refreshing the data
	const handleRefresh = () => {
		if (onRefresh) {
			setIsRefreshing(true);
			try {
				onRefresh();
			} finally {
				// Set a timer to reset the refreshing state even if there's an error
				setTimeout(() => {
					setIsRefreshing(false);
				}, 1000);
			}
		}
	};

	// Handle clearing the search input
	const clearSearch = () => {
		setFilters((prev) => ({ ...prev, search: '' }));
	};

	// Update renderHeader to include search and sort controls
	const renderHeader = () => (
		<div className="flex flex-wrap items-start justify-between gap-4 p-4 border-b border-slate-200">
			<div className="flex flex-col flex-wrap items-start justify-between gap-4 p-4 border-b border-slate-200">
				{/* #region Search & filter */}
				<div className="flex flex-wrap items-center gap-3">
					<div className="relative">
						{filters.search ? (
							<X 
								className="absolute w-4 h-4 text-red-500 -translate-y-1/2 cursor-pointer left-3 top-1/2" 
								onClick={clearSearch}
							/>
						) : (
							<Search className="absolute w-4 h-4 text-green-500 -translate-y-1/2 left-3 top-1/2" />
						)}
						<input
							type="text"
							placeholder="Search name or email..."
							className="w-full py-2 pl-10 pr-4 text-sm border border-green-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 md:w-64"
							value={filters.search}
							onChange={(e) => {
								setFilters((prev) => ({ ...prev, search: e.target.value }));
							}}
						/>
					</div>

					<div className="flex flex-wrap gap-2">
						<select
							className="px-3 py-2 text-sm border border-green-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
							value={filters.statusFilter}
							onChange={(e) => {
								setFilters((prev) => ({
									...prev,
									statusFilter: e.target.value as any,
								}));
							}}
						>
							<option value="all">All Status</option>
							<option value="present">Present</option>
							<option value="absent">Absent</option>
						</select>

						<select
							className="px-3 py-2 text-sm border border-green-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
							value={filters.typeFilter}
							onChange={(e) => {
								setFilters((prev) => ({
									...prev,
									typeFilter: e.target.value as any,
								}));
							}}
						>
							<option value="all">All Types</option>
							<option value="candidate">Participants</option>
							<option value="visitor">Visitors</option>
							<option value="judge">Judges</option>
						</select>
						
						<button
							className="inline-flex items-center px-3 py-2 text-sm text-white bg-green-600 rounded-md hover:bg-green-700"
							onClick={handleRefresh}
							disabled={isRefreshing || isLoading}
						>
							<RefreshCw className={`w-4 h-4 mr-1 ${isRefreshing ? 'animate-spin' : ''}`} />
							{isRefreshing ? 'Refreshing...' : 'Refresh'}
						</button>
					</div>
				</div>
				{/*  #endregion */}
				{/* #region Change Room */}
				<div className="flex flex-wrap items-center gap-2">
					<div className="selector-container">
						<select
							className="px-3 py-2 text-sm border border-green-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
							value={selectedRoomId}
							onChange={(e) => {
								setSelectedRoomId(e.target.value);
							}}
							disabled={
								selectedCandidates.length === 0 || changeRoomMutation.isLoading
							}
						>
							<option value="">Select Room</option>
							<option value="0">Unassigned</option>
							{sortedRooms.map((room) => (
								<option key={room.id} value={room.id}>
									{room.name}
								</option>
							))}
						</select>
					</div>

					<button
						className={`inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-medium w-full md:w-auto mb-2 md:mb-0
            ${
							selectedCandidates.length === 0 ||
							!selectedRoomId ||
							isChangingRoom
								? "bg-slate-100 text-slate-400 cursor-not-allowed"
								: "bg-green-600 text-white hover:bg-green-700 shadow-sm"
						} transition-colors`}
						onClick={handleChangeRoom}
						disabled={
							selectedCandidates.length === 0 ||
							!selectedRoomId ||
							isChangingRoom
						}
					>
						{isChangingRoom ? (
							<>
								<Loader2 className="w-4 h-4 mr-2 animate-spin" />
								Changing...
							</>
						) : (
							<>
								<Clipboard className="w-4 h-4 mr-2" />
								Assign Room
							</>
						)}
					</button>

					<button
						className={`inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-medium w-full md:w-auto mb-2 md:mb-0
            ${
							selectedCandidates.length === 0 || isSendingBadge
								? "bg-slate-100 text-slate-400 cursor-not-allowed"
								: "bg-green-800 text-white hover:bg-amber-700 shadow-sm"
						} transition-colors`}
						onClick={handleSendBadge}
						disabled={selectedCandidates.length === 0 || isSendingBadge}
					>
						{isSendingBadge ? (
							<>
								<Loader2 className="w-4 h-4 mr-2 animate-spin" />
								Sending...
							</>
						) : (
							<>
								<Send className="w-4 h-4 mr-2" />
								Send Badge
							</>
						)}
					</button>

					{selectedCandidates.length > 0 && (
						<div className="px-2 py-1 text-xs font-medium text-green-800 bg-green-100 rounded-full">
							{selectedCandidates.length} selected
						</div>
					)}
				</div>
			</div>
			{/*  #endregion */}
			{/* #region Change Layout */}
			<div className="flex flex-col items-center gap-2">
				<div>
					{/* Sorting */}
					<button
						onClick={() => {
							setFilters((prev) => ({
								...prev,
								sortDirection: prev.sortDirection === "asc" ? "desc" : "asc",
							}));
						}}
						className="p-2 text-green-600 rounded-md hover:bg-green-50"
					>
						<ArrowUpDown className="w-4 h-4" />
					</button>
					<select
						className="px-3 py-2 text-sm border border-green-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
						value={filters.sortBy || ""}
						onChange={(e) => {
							setFilters((prev) => ({
								...prev,
								sortBy: (e.target.value as any) || null,
							}));
						}}
					>
						<option value="">Sort by...</option>
						<option value="name">Name</option>
						<option value="email">Email</option>
						<option value="status">Status</option>
						<option value="room">Room</option>
					</select>
					{/* end sorting */}
				</div>
				{/* change layout */}
				<div className="flex flex-row items-center gap-2">
					<div className="relative">
						<button
							onClick={() => {
								setShowColumnSettings(!showColumnSettings);
							}}
							className="p-2 text-green-600 border border-green-300 rounded-md hover:bg-green-50"
							aria-label="Column Settings"
						>
							{showColumnSettings ? (
								<EyeOff className="w-4 h-4" />
							) : (
								<Eye className="w-4 h-4" />
							)}
						</button>
						{renderColumnSettings()}
					</div>
					<div className="inline-flex items-center rounded-md shadow-sm">
						<button
							onClick={() => {
								setViewMode("accordion");
							}}
							className={`px-3 py-2 rounded-l-md border ${
								viewMode === "accordion"
									? "bg-green-600 text-white border-green-600"
									: "bg-white text-green-600 border-green-300 hover:bg-green-50"
							}`}
							aria-label="Accordion View"
						>
							<Layout className="w-4 h-4" />
							{!isMobile && <span className="ml-2">Accordion</span>}
						</button>
						<button
							onClick={() => {
								setViewMode("list");
							}}
							className={`px-3 py-2 rounded-r-md border-y border-r ${
								viewMode === "list"
									? "bg-green-600 text-white border-green-600"
									: "bg-white text-green-600 border-green-300 hover:bg-green-50"
							}`}
							aria-label="List View"
						>
							<List className="w-4 h-4" />
							{!isMobile && <span className="ml-2">List</span>}
						</button>
					</div>
				</div>
				{/* end change layout */}
			</div>
			{/* #endregion */}
		</div>
	);

	// Render confirmation dialog
	const renderConfirmationDialog = () => {
		if (!showConfirmDialog) return null;

		const action =
			showConfirmDialog.action === "present" ? "present" : "absent";

		return (
			<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
				<div className="max-w-md p-6 mx-4 bg-white rounded-lg shadow-xl">
					<div className="flex items-center mb-4 text-amber-500">
						<AlertTriangle className="w-6 h-6 mr-2" />
						<h3 className="font-medium text-titleMedium">
							Confirm Status Change
						</h3>
					</div>
					<p className="mb-4 text-slate-600">
						Are you sure you want to mark this candidate as {action}?
					</p>
					<div className="flex justify-end gap-3">
						<button
							className="px-4 py-2 border rounded-md border-slate-300 text-slate-700 hover:bg-slate-50"
							onClick={() => {
								setShowConfirmDialog(null);
							}}
						>
							Cancel
						</button>
						<button
							className="px-4 py-2 text-white bg-green-600 rounded-md hover:bg-green-700"
							onClick={() => {
								handleTogglePresence(
									showConfirmDialog.candidateId,
									showConfirmDialog.action === "absent"
								);
							}}
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		);
	};

	// Update renderUserRow
	const renderUserRow = (user: Candidate) => (
		<React.Fragment key={user.id}>
			<tr className={`border-b ${getUserRowClass(user)}`}>
				<td className="px-4 py-3 text-sm whitespace-nowrap">
					<input
						type="checkbox"
						className="w-4 h-4 text-green-600 border-gray-300 rounded form-checkbox focus:ring-green-500"
						checked={selectedCandidates.includes(user.id)}
						onChange={(e) => {
							handleCandidateSelect(user.id, e.target.checked);
						}}
					/>
				</td>
				{viewMode === "list" && (
					<td className="px-4 py-3 text-sm font-medium whitespace-nowrap text-slate-700">
						{user?.room?.id ? roomMap[user.room.id] : "Unassigned"}
					</td>
				)}
				{visibleColumns?.room && (
					<td className="px-4 py-3 text-sm whitespace-nowrap">
						<div className="flex items-center">
							<MapPin
								className={`h-4 w-4 mr-1 ${
									user?.room?.id ? "text-green-500" : "text-slate-400"
								}`}
							/>
							<span>{user?.room?.name || "Unassigned"}</span>
						</div>
					</td>
				)}
				{visibleColumns?.email && (
					<td className="px-4 py-3 text-sm whitespace-nowrap text-slate-600">
						{user?.email || "-"}
					</td>
				)}
				{visibleColumns?.name && (
					<td className="px-4 py-3 text-sm whitespace-nowrap text-slate-700">
						{user?.firstName || "-"}
					</td>
				)}
				{visibleColumns?.lastName && (
					<td className="px-4 py-3 text-sm whitespace-nowrap text-slate-700">
						{user?.lastName || "-"}
					</td>
				)}
				{visibleColumns?.present && (
					<td className="px-4 py-3 whitespace-nowrap">
						<div className="flex items-center gap-2">
							<span
								className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
									user?.isConfirmed
										? "bg-green-100 text-green-800"
										: "bg-red-100 text-slate-800"
								}`}
							>
								{user?.isConfirmed ? "Present" : "Absent"}
							</span>
							<span
								className={`inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-xs font-medium ${
									user?.userType === "CANDIDATE"
										? "bg-green-100 text-green-800"
										: "bg-startgreen_tertiary-100/20 text-amber-800"
								}`}
							>
								{user?.userType === "CANDIDATE" ? (
									<Users className="w-3 h-3" />
								) : (
									<User className="w-3 h-3" />
								)}
								{user?.userType === "CANDIDATE" 
									? "PAR" 
									: user?.userType === "VISITOR" 
										? "VIS" 
										: user?.userType === "JUDGE" 
											? "JUD" 
											: user?.userType || ""}
							</span>
						</div>
					</td>
				)}
				<td className="px-4 py-3 text-sm text-right whitespace-nowrap">
					<div className="flex items-center justify-end space-x-2">
						<button
							className="p-1 text-green-600 rounded-full hover:bg-green-100"
							onClick={() => {
								handleTogglePresence(user.id, user?.isConfirmed);
							}}
							title={user?.isConfirmed ? "Mark as Absent" : "Mark as Present"}
						>
							{user?.isConfirmed ? (
								<X className="w-4 h-4" />
							) : (
								<Check className="w-4 h-4" />
							)}
						</button>
						<button
							className="p-1 rounded-full hover:bg-slate-100"
							onClick={() => {
								toggleUser(user.id);
							}}
							title={expandedUsers[user.id] ? "Hide Details" : "Show Details"}
						>
							{expandedUsers[user.id] ? (
								<ChevronUp className="w-4 h-4 text-slate-600" />
							) : (
								<ChevronDown className="w-4 h-4 text-slate-600" />
							)}
						</button>
					</div>
				</td>
			</tr>
			{expandedUsers[user.id] && (
				<tr className="bg-slate-50">
					<td colSpan={colSpan} className="p-0">
						<div className="p-4 space-y-4">
							<div className="grid gap-4 md:grid-cols-3">
								<div className="p-3 bg-white border rounded-md shadow-sm border-slate-200">
									<h4 className="flex items-center mb-2 text-sm font-medium text-slate-900">
										<UserCheck className="w-4 h-4 mr-2 text-slate-400" />
										Badge
									</h4>
									{user.badgeBase64 ? (
										<div className="flex justify-center">
											<img
												src={`${user.badgeBase64}`}
												alt="Badge"
												className="object-contain h-16"
												loading="lazy"
											/>
										</div>
									) : (
										<div className="text-xs italic text-center text-slate-500">
											No badge available
										</div>
									)}
								</div>
							</div>
						</div>
					</td>
				</tr>
			)}
		</React.Fragment>
	);

	// Render list view with filtered data
	const renderListView = () => (
		<div className="overflow-x-auto">
			<table className="min-w-full border-collapse">
				<thead>
					<tr className="border-b border-green-100 bg-green-50">
						<th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-green-700 uppercase">
								<input
								type="checkbox"
								className="w-4 h-4 text-green-600 border-gray-300 rounded form-checkbox focus:ring-green-500"
								onChange={(e) => {
									if (e.target.checked) {
										setSelectedCandidates(filteredData.map((c) => c.id));
									} else {
										setSelectedCandidates([]);
									}
								}}
								checked={
									filteredData.length > 0 &&
									filteredData.every((c) => selectedCandidates.includes(c.id))
								}
							/>
						</th>
						<th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-green-700 uppercase">
							Room
						</th>
						{visibleColumns?.room && (
							<th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-green-700 uppercase">
								Room Detail
							</th>
						)}
						{visibleColumns?.email && (
							<th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-green-700 uppercase">
								Email
							</th>
						)}
						{visibleColumns?.name && (
							<th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-green-700 uppercase">
								First Name
							</th>
						)}
						{visibleColumns?.lastName && (
							<th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-green-700 uppercase">
								Last Name
							</th>
						)}
						{visibleColumns?.present && (
							<th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-green-700 uppercase">
								Status
							</th>
						)}
						<th className="px-4 py-3 text-xs font-medium tracking-wider text-right text-green-700 uppercase">
							Actions
						</th>
					</tr>
				</thead>
				<tbody>{filteredData.map((user) => renderUserRow(user))}</tbody>
			</table>
		</div>
	);

	// Render the accordion view with filtered data
	const renderAccordionView = () => (
		<div className="p-4 space-y-4">
			{Object.keys(groupedCandidates).length === 0 && (
				<div className="py-8 text-center text-slate-500">
					No results match your search criteria
				</div>
			)}

			{groupedCandidates["Unassigned"] && (
				<div
					key="Unassigned"
					className="overflow-hidden border rounded-md border-slate-200"
				>
					<button
						onClick={() => {
							toggleRoom("Unassigned");
						}}
						className="flex items-center justify-between w-full px-4 py-3 font-medium text-left transition-colors bg-slate-50 hover:bg-slate-100 text-slate-700"
					>
						<div className="flex items-center gap-2">
							<UserX className="w-5 h-5 text-slate-400" />
							<span>Unassigned</span>
							<div className="flex items-center gap-2 ml-3">
								{getRoomCounts("Unassigned").participantCount > 0 && (
									<span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
										<Users className="w-3 h-3" />
										{getRoomCounts("Unassigned").participantCount}
									</span>
								)}
								{getRoomCounts("Unassigned").visitorCount > 0 && (
									<span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-xs font-medium bg-startgreen_tertiary-100/20 text-amber-800">
										<User className="w-3 h-3" />
										{getRoomCounts("Unassigned").visitorCount}
									</span>
								)}
							</div>
						</div>
						<ChevronDown
							className={`h-5 w-5 text-slate-400 transform transition-transform ${
								expandedRooms["Unassigned"] ? "rotate-180" : ""
							}`}
						/>
					</button>

					{expandedRooms["Unassigned"] && (
						<div className="border-t border-slate-200">
							<div className="overflow-x-auto">
								<table className="min-w-full border-collapse">
									<thead>
										<tr className="border-b bg-slate-50 border-slate-200">
											<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
												<input
													type="checkbox"
													className="w-4 h-4 text-green-600 border-gray-300 rounded form-checkbox focus:ring-green-500"
													onChange={(e) => {
														handleSelectAllInRoom(
															"Unassigned",
															e.target.checked
														);
													}}
													checked={
														groupedCandidates["Unassigned"].length > 0 &&
														groupedCandidates["Unassigned"].every((c) =>
															selectedCandidates.includes(c.id)
														)
													}
												/>
											</th>
											{visibleColumns?.room && (
												<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
													Room Detail
												</th>
											)}
											{visibleColumns?.email && (
												<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
													Email
												</th>
											)}
											{visibleColumns?.name && (
												<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
													First Name
												</th>
											)}
											{visibleColumns?.lastName && (
												<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
													Last Name
												</th>
											)}
											{visibleColumns?.present && (
												<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
													Status
												</th>
											)}
											<th className="px-4 py-3 text-xs font-medium tracking-wider text-right uppercase text-slate-500">
												Actions
											</th>
										</tr>
									</thead>
									<tbody>
										{groupedCandidates["Unassigned"].map((user) =>
											renderUserRow(user)
										)}
									</tbody>
								</table>
							</div>
						</div>
					)}
				</div>
			)}

			{sortedRooms.map((room) => {
				// Skip rooms with no candidates after filtering
				if (
					!groupedCandidates[room.name] ||
					groupedCandidates[room.name].length === 0
				) {
					return null;
				}

				return (
					<div
						key={room.id}
						className="overflow-hidden border rounded-md border-slate-200"
					>
						<button
							onClick={() => {
								toggleRoom(room.name);
							}}
							className="flex items-center justify-between w-full px-4 py-3 font-medium text-left transition-colors bg-slate-50 hover:bg-slate-100 text-slate-700"
						>
							<div className="flex items-center gap-2">
								<Users className="w-5 h-5 text-slate-400" />
								<span>{room.name}</span>
								<div className="flex items-center gap-2 ml-3">
									{getRoomCounts(room.name).participantCount > 0 && (
										<span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
											<Users className="w-3 h-3" />
											{getRoomCounts(room.name).participantCount}
										</span>
									)}
									{getRoomCounts(room.name).visitorCount > 0 && (
										<span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
											<User className="w-3 h-3" />
											{getRoomCounts(room.name).visitorCount}
										</span>
									)}
								</div>
							</div>
							<ChevronDown
								className={`h-5 w-5 text-slate-400 transform transition-transform ${
									expandedRooms[room.name] ? "rotate-180" : ""
								}`}
							/>
						</button>

						{expandedRooms[room.name] && (
							<div className="border-t border-slate-200">
								{groupedCandidates[room.name] && (
									<div className="overflow-x-auto">
										<table className="min-w-full border-collapse">
											<thead>
												<tr className="border-b bg-slate-50 border-slate-200">
													<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
														<input
															type="checkbox"
															className="w-4 h-4 text-green-600 border-gray-300 rounded form-checkbox focus:ring-green-500"
															onChange={(e) => {
																handleSelectAllInRoom(
																	room.name,
																	e.target.checked
																);
															}}
															checked={
																groupedCandidates[room.name].length > 0 &&
																groupedCandidates[room.name].every((c) =>
																	selectedCandidates.includes(c.id)
																)
															}
														/>
													</th>
													{visibleColumns?.room && (
														<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
															Room Detail
														</th>
													)}
													{visibleColumns?.email && (
														<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
															Email
														</th>
													)}
													{visibleColumns?.firstName && (
														<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
															First Name
														</th>
													)}
													{visibleColumns?.lastName && (
														<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
															Last Name
														</th>
													)}
													{visibleColumns?.present && (
														<th className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase text-slate-500">
															Status
														</th>
													)}
													<th className="px-4 py-3 text-xs font-medium tracking-wider text-right uppercase text-slate-500">
														Actions
													</th>
												</tr>
											</thead>
											<tbody>
												{groupedCandidates[room.name].map((user) =>
													renderUserRow(user)
												)}
											</tbody>
										</table>
									</div>
								)}
							</div>
						)}
					</div>
				);
			})}
		</div>
	);

	return (
		<div className="overflow-hidden bg-white border rounded-lg shadow-lg border-slate-200">
			{renderHeader()}
			{viewMode === "accordion" ? renderAccordionView() : renderListView()}
			{renderConfirmationDialog()}
		</div>
	);
};

export default CandidatesTable;
