export const navItems = [
	{
		label: "Tableau de bord",
		path: "/startgreen-by-iptic/dashboard",
		roles: ["admin"],
	},
	{
		label: "Candidats",
		path: "/startgreen-by-iptic/dashboard/participants",
		roles: ["admin"],
	},
	{
		label: "Visiteurs",
		path: "/startgreen-by-iptic/dashboard/visitors",
		roles: ["admin"],
	},
	{
		label: "Juges",
		path: "/startgreen-by-iptic/dashboard/judges",
		roles: ["admin"],
	},
	{
		label: "Administrateurs",
		path: "/startgreen-by-iptic/dashboard/admins",
		roles: ["admin"],
	},
	{
		label: "Hackathon",
		path: "/startgreen-by-iptic/dashboard/hackathon",
		roles: ["admin", "judge"],
	},
	{
		label: "Phases",
		path: "/startgreen-by-iptic/dashboard/phases",
		roles: ["admin"],
	},
	{
		label: "Emails",
		path: "/startgreen-by-iptic/dashboard/emails",
		roles: ["admin"],
	},
	{
		label: "Paramètres",
		path: "/startgreen-by-iptic/dashboard/settings",
		roles: ["admin"],
	},
	{
		label: "mail-builder",
		path: "/startgreen-by-iptic/dashboard/mail-builder",
		roles: ["admin"],
		hide: true,
	},
];
