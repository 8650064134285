import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ParticipantsScreen from "@pages/(startgreen)/views/(participants)";
import HackathonScreen from "@pages/(startgreen)/views/(hackathon)";

const getStartGreen = async () => {
	const response = await axios.get("http://localhost:4400/v2");
	return response.data;
};
const Page = () => {
	const { t: tMeta } = useTranslation("metadata");
	return (
		<>
			<Meta
				title={"StartGreen by IPTIC - Participants"}
				template={"%s | Coachini"}
				description={tMeta("about-page.description")}
				keywords={[
					"about us",
					"about coachini",
					"know more",
					"coaching",
					"coachini",
				]}
				robots="index,follow"
				ogTitle={tMeta("about-page.title")}
				ogDescription={tMeta("about-page.description")}
			/>

			<HackathonScreen navbarTitle={"Hackathon"} submissionType={"USER"} />
		</>
	);
};

export const Route = createFileRoute(
	"/(main)/startgreen-by-iptic/dashboard/hackathon/"
)({
	component: Page,
});
