import { useState, useMemo } from "react";

interface FilterOptions {
	search: string;
	sortBy: "name" | "email" | "status" | "room" | null;
	sortDirection: "asc" | "desc";
	statusFilter: "all" | "present" | "absent";
	typeFilter: "all" | "candidate" | "visitor" | "judge";
}

interface Candidate {
	id: string;
	name?: string;
	lastName?: string;
	email?: string;
	isConfirmed?: boolean;
	userType?: "CANDIDATE" | "VISITOR" | string;
	room?: { id: string; name: string };
}

export const useFilter = (data: Candidate[]) => {
	const [filters, setFilters] = useState<FilterOptions>({
		search: "",
		sortBy: null,
		sortDirection: "asc",
		statusFilter: "all",
		typeFilter: "all",
	});

	const filteredData = useMemo(() => {
		let result = [...data];

		// Apply status filter
		if (filters.statusFilter !== "all") {
			result = result.filter((item) => {
				if (filters.statusFilter === "present") {
					return item.isConfirmed === true;
				} else {
					return item.isConfirmed === false;
				}
			});
		}

		// Apply type filter
		if (filters.typeFilter !== "all") {
			result = result.filter((item) => {
				if (filters.typeFilter === "candidate") {
					return item.userType === "CANDIDATE";
				} else if (filters.typeFilter === "visitor") {
					return item.userType === "VISITOR";
				} else {
					return item.userType === "JUDGE";
				}
			});
		}

		// Apply search filter
		if (filters.search) {
			const searchLower = filters.search.toLowerCase();
			result = result.filter(
				(item) =>
					item.name?.toLowerCase().includes(searchLower) ||
					item.lastName?.toLowerCase().includes(searchLower) ||
					item.email?.toLowerCase().includes(searchLower)
			);
		}

		// Apply sorting
		if (filters.sortBy) {
			result.sort((a, b) => {
				let compareA: string | boolean | undefined;
				let compareB: string | boolean | undefined;

				switch (filters.sortBy) {
					case "name":
						compareA = `${a.name || ""} ${a.lastName || ""}`.toLowerCase();
						compareB = `${b.name || ""} ${b.lastName || ""}`.toLowerCase();
						break;
					case "email":
						compareA = a.email?.toLowerCase();
						compareB = b.email?.toLowerCase();
						break;
					case "status":
						compareA = a.isConfirmed;
						compareB = b.isConfirmed;
						break;
					case "room":
						compareA = a.room?.name?.toLowerCase();
						compareB = b.room?.name?.toLowerCase();
						break;
				}

				if (compareA === undefined) return 1;
				if (compareB === undefined) return -1;
				if (compareA === compareB) return 0;

				const sortOrder = filters.sortDirection === "asc" ? 1 : -1;
				return compareA < compareB ? -sortOrder : sortOrder;
			});
		}

		return result;
	}, [data, filters]);

	return {
		filters,
		setFilters,
		filteredData,
	};
};
