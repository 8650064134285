import { useEffect, useState } from "react";
import { Reader } from "@usewaypoint/email-builder";

export enum AllowedMimeTypes {
	PDF = "application/pdf",
	JPEG = "image/jpeg",
	PNG = "image/png",
	DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	TXT = "text/plain",
}
interface AttachmentData {
	name: string;
	type: AllowedMimeTypes;
	content: string | File; // Can be either base64 string or File object
	isNew?: boolean;
}
export const TemplateEditor = ({ selectedTemplate, onSave }) => {
	const [templateName, setTemplateName] = useState(
		selectedTemplate ? selectedTemplate.name : ""
	);
	const [templateSubject, setTemplateSubject] = useState(
		selectedTemplate ? selectedTemplate.subject : ""
	);
	const [templateContent, setTemplateContent] = useState(
		selectedTemplate ? selectedTemplate.template : ""
	);
	const [dynamicFields, setDynamicFields] = useState(
		selectedTemplate ? selectedTemplate.dynamicFields : []
	);
	const [isSaving, setIsSaving] = useState(false);
	const [attachments, setAttachments] = useState<AttachmentData[]>(
		selectedTemplate?.attachments?.map((a) => ({
			name: a.name,
			type: a.type,
			content: a.content, // Already base64
			isNew: false,
		})) || []
	);

	useEffect(() => {
		if (selectedTemplate) {
			setTemplateName(selectedTemplate.name);
			setTemplateSubject(selectedTemplate.subject || "");
			setTemplateContent(selectedTemplate.template);
			setDynamicFields(selectedTemplate.dynamicFields || []);
		}
	}, [selectedTemplate]);

	const handleAddField = () => {
		setDynamicFields([...dynamicFields, ""]);
	};

	const handleFieldChange = (index, value) => {
		const newFields = [...dynamicFields];
		newFields[index] = value;
		setDynamicFields(newFields);
	};

	const handleRemoveField = (index) => {
		const newFields = dynamicFields.filter((_, i) => i !== index);
		setDynamicFields(newFields);
	};

	useEffect(() => {
		if (selectedTemplate) {
			// Reset attachments when template changes
			setAttachments(selectedTemplate.attachments || []);
		}
	}, [selectedTemplate]);

	const handleUploadAttachments = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const files = Array.from(event.target.files || []) as File[];

		// Validate file types and map to AttachmentData
		const newAttachments = files
			.filter((file) =>
				Object.values(AllowedMimeTypes).includes(file.type as AllowedMimeTypes)
			)
			.map((file) => ({
				name: file.name,
				type: file.type as AllowedMimeTypes,
				content: file, // Store the File object directly
				isNew: true, // Mark as new upload
			}));

		if (newAttachments.length !== files.length) {
			alert("Certains fichiers ont un format non supporté");
		}

		// Update state with new attachments
		setAttachments((prev) => [...prev, ...newAttachments]);
	};

	const handleRemoveAttachment = (index) => {
		setAttachments((prev) => prev.filter((_, i) => i !== index));
	};

	const handleSave = async () => {
		setIsSaving(true);

		try {
			// Process attachments differently based on their type
			const processedAttachments = await Promise.all(
				attachments.map(async (attachment) => {
					// If it's a File object (new upload)
					if (attachment.content instanceof File) {
						return {
							name: attachment.name,
							type: attachment.type,
							content: await readFileAsBase64(attachment.content),
						};
					}
					// If it's already base64 (existing attachment)
					return {
						name: attachment.name,
						type: attachment.type,
						content: attachment.content,
					};
				})
			);

			onSave({
				id: selectedTemplate?.id,
				name: templateName,
				subject: templateSubject,
				template: templateContent,
				dynamicFields,
				attachments: processedAttachments,
			});
		} catch (error) {
			console.error("Error saving template:", error);
			alert("Erreur lors de l'enregistrement");
		} finally {
			setIsSaving(false);
		}
	};
	const readFileAsBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result.toString().split(",")[1]);
			reader.onerror = (error) => reject(error);
			reader.readAsDataURL(file);
		});
	};
	return (
		<div className="template-editor space-y-4">
			<input
				type="text"
				value={templateName}
				onChange={(e) => setTemplateName(e.target.value)}
				placeholder="Nom du modèle"
				className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
				disabled={isSaving}
			/>
			<input
				type="text"
				value={templateSubject}
				onChange={(e) => setTemplateSubject(e.target.value)}
				placeholder="Sujet du modèle"
				className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
				disabled={isSaving}
			/>
			<div className="dynamic-fields space-y-2">
				<label className="block text-gray-700">Champs dynamiques :</label>
				{dynamicFields.map((field, index) => (
					<div key={index} className="flex space-x-2 items-center">
						<input
							type="text"
							value={field}
							onChange={(e) => handleFieldChange(index, e.target.value)}
							placeholder={`Champ ${index + 1}`}
							className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
							disabled={isSaving}
						/>
						<button
							onClick={() => handleRemoveField(index)}
							className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
							disabled={isSaving}
						>
							Supprimer
						</button>
					</div>
				))}

				<button
					className="bg-startgreen_darkblue-100 text-white px-4 py-2 rounded hover:bg-blue-600"
					onClick={handleAddField}
					disabled={isSaving}
				>
					Ajouter un champ dynamique
				</button>
			</div>

			<div className="attachments space-y-2">
				<label className="block text-gray-700">Pièces jointes:</label>

				<input
					type="file"
					multiple
					onChange={handleUploadAttachments}
					className="w-full p-2 border rounded"
					accept={Object.values(AllowedMimeTypes).join(",")}
					disabled={isSaving}
				/>

				<div className="attachment-list space-y-2">
					{attachments.map((file, index) => (
						<div
							key={index}
							className="flex items-center justify-between p-2 bg-gray-100 rounded"
						>
							<div>
								<span className="font-medium">{file.name}</span>
								<span className="text-sm text-gray-500 ml-2">
									({file.type})
								</span>
							</div>
							<button
								onClick={() => handleRemoveAttachment(index)}
								className="text-red-500 hover:text-red-700"
								disabled={isSaving}
							>
								×
							</button>
						</div>
					))}
				</div>
			</div>
			<Reader document={JSON.parse(templateContent)} rootBlockId="root" />
			<button
				onClick={handleSave}
				className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
				disabled={isSaving}
			>
				{isSaving ? "Enregistrement..." : "Enregistrer le modèle"}
			</button>
		</div>
	);
};
