import React, { useCallback, useState } from "react";
import { cn } from "@core/utils";

interface FileUploaderProps {
	onFileSelect: (file: File) => void;
	disabled?: boolean;
	accept?: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({
	onFileSelect,
	disabled = false,
	accept = ".csv, .xlsx",
}) => {
	const [isDragging, setIsDragging] = useState(false);
	const fileInputRef = React.useRef<HTMLInputElement>(null);

	const handleDragEnter = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			if (!disabled) setIsDragging(true);
		},
		[disabled]
	);

	const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(false);
	}, []);

	const handleDragOver = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			if (!disabled) setIsDragging(true);
		},
		[disabled]
	);

	const handleDrop = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			setIsDragging(false);

			if (disabled) return;

			if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
				const file = e.dataTransfer.files[0];
				const fileExtension = file.name.split(".").pop()?.toLowerCase();

				if (
					fileExtension === "csv" ||
					fileExtension === "xlsx" ||
					fileExtension === "xls"
				) {
					onFileSelect(file);
				}
			}
		},
		[disabled, onFileSelect]
	);

	const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			onFileSelect(e.target.files[0]);
		}
	};

	const handleClick = () => {
		if (!disabled && fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	return (
		<div
			className={cn(
				"border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-all",
				isDragging
					? "border-startgreen_primary-100 bg-startgreen_primary-100/10"
					: "border-gray-300",
				disabled
					? "opacity-50 cursor-not-allowed"
					: "hover:border-startgreen_primary-100"
			)}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDragOver={handleDragOver}
			onDrop={handleDrop}
			onClick={handleClick}
		>
			<input
				type="file"
				ref={fileInputRef}
				className="hidden"
				accept={accept}
				onChange={handleFileSelect}
				disabled={disabled}
			/>
			<div className="flex flex-col items-center justify-center space-y-2">
				<svg
					className={cn(
						"w-12 h-12",
						disabled ? "text-gray-400" : "text-startgreen_primary-100"
					)}
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
					/>
				</svg>
				<p className="text-sm font-medium">
					{isDragging ? (
						"Déposez le fichier ici"
					) : (
						<>
							Glissez-déposez un fichier ou{" "}
							<span className="text-startgreen_primary-100">
								cliquez pour télécharger
							</span>
						</>
					)}
				</p>
				<p className="text-xs text-gray-500">Formats acceptés: CSV, XLSX</p>
			</div>
		</div>
	);
};

export default FileUploader;
